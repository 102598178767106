.ticket-iframe {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100vw - 270px);
  margin: 0 auto;
  max-width: 1180px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 5px !important;
}

.ticket-iframe::-webkit-scrollbar {
  display: none;
}

.ticket-iframe-iframe {
}

.modeler-iframe-wrapper2 {
  height: 100vh;
}

.loader-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: auto;
}

.modeler-iframe-wrapper {
  height: 100vh;
}

.modeler-magic-wrapper {
  background-color: #efefef;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-go-back {
  display: flex;
  width: 100%;
}

.header-pre-wrap {
  justify-content: flex-start;
  display: flex;
  width: 100%;
  align-items: center;
}

.header-wrap {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 20px 3vw;
  width: 100%;
}

.header-wrapper {
  position: absolute !important;
  top: -5px !important;
  right: 10px !important;
  height: 50px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
}

.img-main {
  width: 110px;
}

.ticket-user {
  display: flex;
  gap: 10px;
  align-items: center;
}

.submenu-btn {
  gap: 10px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
  color: black !important;
}

.submenu-btn:hover {
  background-color: transparent !important;
}
.avatar {
  color: transparent;
  border-radius: 50px;
  padding: 5px;
  font-size: 0.7em;
}

@media screen and (max-width: 900px) {
  .header-wrap {
    justify-content: space-between;
    display: flex;
    padding: 0;
    align-items: center;
  }
  .header-go-back {
    position: absolute;
    top: 75px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .ticket-iframe {
    height: 90vh;
    width: calc(100vw - 120px);
  }

  .img-main {
    width: 90px;
  }
}

@media screen and (max-width: 570px) {
  .modeler-magic-wrapper {
    padding-top: 15px !important;
    align-items: flex-start;
    height: 100vh !important;
  }
  .header-wrap {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .header-wrapper {
    margin-left: -12px;
    margin-right: -12px;
  }
  .modeler-iframe-wrapper {
    padding: 0px;
  }
  .ticket-iframe {
    height: calc(100vh - 120px);
    width: calc(100%);
    margin-top: 0;
  }
}
