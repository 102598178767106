.accordion-wrapper {
  gap: 1rem;
  overflow: hidden;
  overflow-y: scroll;
}

.accordion-wrapper-item {
  overflow: hidden;
}

.approver {
  display: flex !important;
  gap: 4px !important;
  justify-content: center !important;
  flex-direction: row !important;
  background-color: transparent !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0;
  box-shadow: none !important;
  margin-right: -22px !important;
}

.accordion-add {
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px !important;
  background-color: #6f7a88 !important;
  box-shadow: none !important;
  padding-left: 20px !important;
  padding-top: 8px !important;
  border-radius: 50% !important;
  margin: 10px 10px 10px 14px !important;
}

.flex-rows {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-right: -110px !important; */
}

.accordion-toggler {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  max-width: 100% !important;
  min-width: 100% !important;
  margin-top: -3px !important;
  margin-right: 0 !important;
  padding: 10px 22px !important;
  cursor: pointer;
  box-shadow: none !important;
  height: 40px !important;
  color: white !important;
  gap: 10px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: #3e678e !important;
}


.header-tickets-bg {
  background-color: rgb(181, 181, 181) !important;
  width: 100% !important;
}

.header-tickets-bg3 {
  background: rgb(181, 181, 181) !important;
  width: 100% !important;
}

.accordion-toggler:hover {
  background-color: #939393e9 !important;
}

.accordion-item {
  color: white;
  margin: 0px 0 0 0;
  font-size: 1em !important;
  font-weight: 500 !important;
}

.accordion-toggler-icon {
  height: 16px;
  font-weight: 800;
}

.green {
  background-color: #52b67aa4;
  color: white !important;
}

.yellow {
  background-color: #6ad0ecc7;
  color: white !important;
}

.red {
  background-color: #cb3234;
  color: white !important;
}

.gray {
  background-color: #4a4545c3;
  color: white !important;
}

.ticket-amount-btn {
  border-radius: 8px;
  color: white;
  width: auto;
  height: auto;
  font-size: 0.9em;
  padding: 3px 6px;
  margin: 0;
  margin-right: 0px;
}

.pdf {
  height: 27px !important;
  min-width: 30px !important;
  margin-bottom: 0px !important;
  margin-right: 10px !important;
  border-radius: 10px !important;
  background-color: rgba(62, 85, 82, 0.374) !important;
  box-shadow: none !important;
}

.tire {
  min-width: 90px;
}

.accordion-wrapper-list2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-width: calc(100vw - 220px);
  height: auto;
  gap: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.191) !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
  overflow: hidden;
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.accordion-wrapper-list::-webkit-scrollbar {
  display: none;
}

.accordion-txt {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white !important;
}

.badge-container {
  height: 100%;
  display: flex;
  width: 250px !important;
  flex-direction: column-reverse;
  justify-content: flex-end;
  background-color: #efefef;
  color: #6f7a88;
  border-radius: 8px;
  border: 1px solid #6f7a884b;
}

.badge-container2 {
  height: 100%;
  display: flex;
  width: 250px !important;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #efefef;
  color: #6f7a88;
  border-radius: 10px;
  border: 1px solid #6f7a884b;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(-15px);
  }

  60% {
    -webkit-transform: translateX(-7px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-15px);
  }

  60% {
    transform: translateX(-7px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.mb-nav {
  height: 15px;
  margin-right: 8px;
}

.mb-nav2 {
  height: 10px;
  margin-right: 8px;
}

.team-name {
  margin: 0;
}

.badge-izq {
  color: white;
  width: auto;
  height: auto;
  font-size: 1.2em;
  padding: 5px 5px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  gap: 5px;
}

.badge-izq2 {
  color: white;
  width: auto;
  height: auto;
  font-size: 0.7em;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bagde-wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  height: auto !important;
  padding: 4px !important;
  border-radius: 10px !important;
}

.bagde-wrapper:hover {
  border-radius: 10px !important;
}

.bagde-wrapper2 {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  height: auto !important;
  padding: 3px !important;
}

.bagde-wrapper2:last-child {
  margin-bottom: 40px;
}

.bagde-wrapper:last-child {
  margin-bottom: 40px;
}

.adjust-field {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 3px;
  padding: 5px !important;
  height: 77%;
  max-height: 60vh;
  overflow-y: scroll !important;
}

.bagde-wrapper-group {
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0px !important;
  padding: 0 !important;
  width: 100% !important;
  min-height: 40px !important;
}

.bagde-wrapper-group:hover {
  background-color: transparent !important;
}

.bagde-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 5px !important;
  min-width: 100% !important;
  text-align: left !important;
  padding: 5px !important;
  border: rgba(255, 255, 255, 0) solid 1px !important;
  height: 100% !important;
}

.bagde-container:hover {
  opacity: 0.9;
  border: #f04 solid 1px !important;
}

.avatar-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border-radius: 5px !important;
  border: 1px solid rgb(108, 106, 106) !important;
  height: 30px !important;
}

.avatar-container:hover {
  background-color: rgb(108, 106, 106) !important;
  color: white !important;
}

.operator-avatar {
  margin-right: 10px;
}

.badge-der {
  background-color: transparent !important;
  height: auto;
  text-align: left;
  padding: 10px;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.badge-der2 {
  background-color: rgba(79, 78, 78, 0.136) !important;
  height: auto;
  text-align: left;
  padding: 7px 10px 12px 10px;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.ticket-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: auto;
}
.badge-no-assined {
  background-color: #3e678e;
  color: white;
  padding: 2px 5px;
  border-radius: 8px;
  width: 90px;
  margin-left: 5px;
  margin-top: 5px;
  font-size: small;
  display: flex;
  justify-content: center;
}

.activity-header2 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  margin: 0px 10px 0px 0px;
}

.assigned-arrow {
  background-color: transparent !important;
  min-height: 30px !important;
  max-height: 30px !important;
  min-width: 20px;
  max-width: 20px;
  box-shadow: none !important;
  padding: 0 !important;
  margin-right: 5px !important;
}

.delete-ticket {
  position: absolute;
  right: 50px;
  top: 10px;
  z-index: 10000000;
}

.btn-delete {
  color: white !important;
  background-color: rgba(255, 0, 0, 0.225) !important;
  display: flex;
  gap: 10px;
  padding: 10px !important;
}
.btn-delete:hover {
  color: white !important;
  background-color: #f04 !important;
}

.actioners-tickets {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  height: 30px;
}

.assigned-arrow:hover {
  background-color: rgba(128, 128, 128, 0.13) !important;
  min-height: 30px !important;
  max-height: 30px !important;
  min-width: 20px;
  max-width: 20px;
  box-shadow: none !important;
  padding: 0 !important;
  margin-right: 5px !important;
}

.no-results {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  color: white !important;
  background-color: #3e678ea4 !important;
  height: 40px !important;
  border-radius: 5px !important;
  padding-left: 15px !important;
}

.unassigned-arrow {
  background-color: #3e678e !important;
  color: white !important;
  box-shadow: none !important;
  min-width: auto !important;
  padding: 7px 7px !important;
  border: #3e678e 1px solid !important;
  display: flex;
  gap: 4px;
}

.unassigned-arrow:hover {
  color: white !important;
  border: #f04 1px solid !important;
  padding: 7px 7px !important;
}

.badge-info {
  display: flex;
  width: 100%;
  color: white;
}

.badge-der-contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8em;
  margin-bottom: 16px;
  margin-right: 20px;
  gap: 7px;
}

.node-cls {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: 3px;
  margin-left: -28px;
  border: 1px solid rgba(255, 255, 255, 0.369);
}

.badge-der-contact-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-weight: 400 !important;
  font-size: 1em !important;
  margin: 0 !important;
}

.badge-member {
  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700 !important;
  padding: 8px;
  color: #3e678e;
}

.badge-member:hover {
  color: white;
}

.badge-der-contact-op {
  max-width: 100% !important;
  height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge-der-time-assigned {
  border-radius: 5px;
  padding: 5px;
  margin-right: 0px;
  color: white;
  font-size: 0.8em !important;
  font-weight: 500;
}

.badge-der-time-difference {
  letter-spacing: 2px;
}

.badge-der-time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
  height: 0px;
  gap: 20px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.refresh-btn {
  background-color: transparent !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  padding: 5px !important;
  box-shadow: none !important;
  height: 28px !important;
}

.resfresh-icon {
  height: 20px;
  width: 20px;
}

.accordion-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(290px);
  height: 100%;
  max-width: 1140px;
}

.thunder-svg {
  fill: black !important;
  color: black;
  height: 20px;
  opacity: 0.9;
}

@media screen and (max-width: 1500px) {
  .accordion-wrapper {
    gap: 1rem;
    overflow: hidden;
    overflow-y: scroll;
  }
  .adjust-field {
    max-height: 65vh;
  }
}

@media screen and (max-width: 900px) {
  .adjust-field {
    max-height: 45vh;
  }
  .accordion-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1120px;
    width: calc(100%);
  }
  .ticket-amount-btn {
    border-radius: 20px;
    width: auto;
    height: auto;
    font-size: 0.7em;
    padding: 2px 4px;
    margin: 0;
  }

  .accordion-wrapper-item {
    width: 100%;
    padding: 0px;
  }

  .badge-der-contact-name {
    max-width: 100% !important;
  }
  .accordion-wrapper {
    gap: 1rem;
    overflow: hidden;
    overflow-y: scroll;
    min-width: 100% !important;
    max-width: 1180px;
  }
}

@media screen and (max-width: 570px) {
  .tire {
    min-width: 60px;
  }
  .accordion-wrapper-item {
    width: calc(100% - 0px);
  }
  .accordion-toggler {
    padding: 5px 25px 5px 15px !important;
  }
  .bagde-wrapper {
    margin-right: 0px !important;
  }

  .ticket-actions {
    gap: 0px;
  }

  .badge-container {
    display: flex;
    width: calc(100vw - 115px) !important;
    background-color: #47678650;
    color: black;
    border-radius: 10px;
  }

  .badge-container2 {
    display: flex;
    width: calc(100vw - 115px) !important;
    background-color: #efefef;
    color: black;
    border-radius: 10px;
  }
}
