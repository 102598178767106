.edit-app-wrapper {
  height: 97%;
  width: 100%;
  padding: 2rem 2rem 2rem 2rem;
  overflow: hidden;
}

.edit-app-container {
  background-color: #efefef;
  height: 99%;
  width: 100%;
  padding: 0 25px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 20px;
  max-width: 1180px;
  margin: 0 auto;
}

.edit-app-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  .edit-app-wrapper {
    height: 100%;
    padding: 0;
  }

  .edit-wrapper {
    padding-left: 0px;
  }

  .edit-app-container {
    padding: 0;
    height: 100%;
  }
}
