.app-form-section {
  padding: 70px 20px 20px 20px !important;
}

.app-form-section-2-columns {
  padding: 2%;
  columns: 2;
}

.view-case-tab-view {
  background-color: #f4f4f4;
  border-radius: 5px;
  margin: 4rem 2rem 2rem 2rem;
}

.view-case-tab-view2 {
  max-width: 100px !important;
  border-radius: 5px;
  margin: -1rem 0rem 0rem 0rem;
}

.tabs {
  margin-top: 40px;
  margin-right: 30px;
}

.view-case-title {
  color: rgba(0, 0, 0, 0.581);
  font-weight: 600;
}

.view-case-value {
  color: rgba(0, 0, 0, 0.581);
  margin: 0;
}

@media screen and (max-width: 1400px) {
  .view-case-tab-view2 {
    border-radius: 5px;
    margin: -1rem -1rem 0rem -2rem;
  }
}

@media screen and (max-width: 900px) {
  .view-case-tab-view2 {
    border-radius: 5px;
    margin: -1rem -1rem 0rem -1.8rem;
  }
  .tabs {
    margin-top: 18px;
    margin-bottom: 1rem;
  }
  .app-form-section {
    padding: 60px 20px 20px 20px !important;
  }
}

@media screen and (max-width: 570px) {
  .view-case-tab-view2 {
    border-radius: 5px;
    margin: -1rem -4rem 0rem -2rem;
    width: 70vw !important;
  }
  app-form-section-2-columns {
    columns: 1;
  }
}

.app-formGroup {
  width: 100%;
  justify-content: left;
  align-items: left;
  padding-left: 2%;
}

.app-form-inputText {
  width: 85%;
  margin-left: auto !important;
}

.app-form-button {
  padding: 20px !important;
  margin-left: auto !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

label {
  font-weight: 500;
  padding-right: 2%;
}

.custom-data {
  white-space: pre !important;
}
