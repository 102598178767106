.user-form-icon-section {
  align-items: center;
}

.user-form-section {
  padding: 60px 30px 20px 40px !important;
  max-width: auto !important;
  background-color: #414f5c !important;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 80vh;
}

.section-scroller {
  max-width: 750px !important;
  min-width: 750px !important;
}

.section-scroller2 {
  width: 100% !important;
  margin: 1vw auto;
  max-width: 900px;
}

.subtitle-data-txt {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
}

.subtitle-data-txt2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: auto;
  text-align: center;
  color: #185f7394;
  font-size: 0.9em !important;
  font-weight: 500 !important;
}

.user-inputText {
  font-size: 1em !important;
  width: 100% !important;
  min-width: 330px !important;
  color: rgba(0, 0, 0, 0.826) !important;
}

.user-inputText:disabled {
  background-color: white !important;
}

.user-inputText3 {
  font-size: 1em !important;
  width: 100% !important;
  min-width: 80% !important;
  color: rgba(0, 0, 0, 0.826) !important;
}

.user-inputText3:disabled {
  background-color: white !important;
}

.half-screen {
  width: 100% !important;
  margin-top: 20px !important;
}

.edit-inputText {
  font-size: 16px !important;
  min-width: 200px;
  background-color: white;
  padding: 8px 20px 8px 10px !important;
  border: none !important;
  color: #495057 !important;
  border-radius: 8px !important;
}

.edit-inputText:disabled {
  font-size: 1em !important;
  width: 100% !important;
  min-width: 380px;
  background-color: white;
  padding: 8px 20px 8px 10px !important;
  border: none !important;
  color: #495057 !important;
}

.option-backgroung {
  background-color: rgba(27, 71, 116, 0.214) !important;
  padding: 1vw !important;
  color: #3e678e;
}

.option-backgroung2 {
  color: #3e678e;
}
.option-backgroung2:hover {
  color: white;
}

.user-dropdown {
  fill: #f04 !important;
  width: 30px !important;
  background-color: #3e678e !important;
  border: none !important;
  outline: #f04;
  margin-left: 10px !important;
}

.user-dropdown:hover {
  fill: #f04 !important;
  width: 30px !important;
  background-color: #f04 !important;
  border: none !important;
  outline: #f04 !important;
  margin-left: 10px !important;
}

.p-dropdown-trigger .user-dropdown {
  background-color: transparent !important;
}

.user-engines {
  margin: 20px 0;
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto 10px auto;
  max-width: 1180px;
}

.flex-wrapper2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 0px 0;
}

.modules-wraper {
  border-radius: 8px;
  height: auto !important;
  width: 100%;
  padding: 10px;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}

.load-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
  width: 100%;
}

.multiselect-modules {
  background-color: #3e678e !important;
  width: 100% !important;
  border-radius: 5px !important;
}

.less-margin {
  margin: 0 !important;
  width: 100% !important;
}

.module-item {
  font-size: 0.8em !important;
  background-color: #3e678e17 !important;
  color: #3e678e !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 15px !important;
  border-radius: 8px;
  border: 1px solid #3e678e !important;
  cursor: pointer;
  margin: 0 !important;
}

.module-item:hover {
  color: #f04 !important;
  background-color: #3e678e29 !important;
  border: 1px solid #f04 !important;
}

.engine-item {
  font-size: 0.8em;
  background-color: #ff880018 !important;
  color: #3e678e;
  display: flex;
  justify-content: crgb(237, 216, 222) r;
  align-items: center;
  height: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #3e678e;
  cursor: pointer;
}

.user-form-section-no-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(54vw);
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 5vw 5vw 20vw 5vw !important;
  max-width: auto !important;
  min-width: auto !important;
}

.user-form-section-no-modal2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 5vw 5vw 20vw 5vw !important;
  max-width: auto !important;
  min-width: auto !important;
}

.user-form-section-no-modal::-webkit-scrollbar {
  display: none;
}

.user-formGroup {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100%;
  min-width: auto;
  max-width: auto;
  align-items: center;
  margin: 15px 0 0px 0 !important;
  gap: 3vw;
  color: #22384d9a !important;
  font-weight: 700 !important;
}

.user-formGroup2 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  width: 100%;
  min-width: auto;
  max-width: auto;
  align-items: center;
  margin: 5px 0 0px 0 !important;
  gap: 10px;
  color: white !important;
}

.recovery-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: transparent !important;
  margin-left: auto !important;
  color: #f04 !important;
}

label {
  min-width: 200px;
  font-weight: 600;
}

.recovery-btn {
  background-color: transparent !important;
  margin-left: auto !important;
  color: #f04 !important;
  padding: 0 !important;
  margin-right: 0 !important;
  text-decoration: underline !important;
  margin-top: -2px !important;
  font-size: 0.8em !important;
}

.recovery-btn:hover {
  background-color: transparent !important;
  color: rgba(255, 0, 68, 0.659) !important;
}

.user-form-button {
  padding: 20px !important;
  margin-left: auto !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.user-input-required-text {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1300px) {
  .section-scroller {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .user-inputText {
    font-size: 1em !important;
    width: 100% !important;
  }

  .edit-inputText {
    font-size: 1em !important;
  }

  .user-formGroup {
    width: auto !important;
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    gap: 1vw;
    min-width: auto;
    align-items: flex-start;
  }
  .half-screen {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .user-form-section-no-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: calc(54vw);
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 3vw 3vw 20vw 3vw !important;
    max-width: auto !important;
    min-width: auto !important;
  }
}

@media screen and (max-width: 1000px) {
  .user-inputText {
    min-width: auto !important;
  }
}

@media screen and (max-width: 900px) {
  .subtitle-data-txt2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: auto !important;
    font-size: 0.8em !important;
    text-align: center;
  }
  .half-screen {
    margin-left: 0px !important;
  }
  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 80vw;
    margin: 0px auto 5px auto;
  }

  .flex-wrapper2 {
    margin: 0px 0 0px 0;
  }

  .user-form-section-no-modal {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 5vw 5vw 20vw 5vw !important;
    width: 100%;
  }

  .user-form-section-no-modal2 {
    display: flex;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    height: 100%;
    width: calc(100vw - 30px);
    margin: 0 auto;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 3vw 3vw 20vw 3vw !important;
    min-width: auto !important;
  }

  .edit-inputText {
    min-width: 50vw;
  }
  .edit-inputText:disabled {
    min-width: 65vw;
  }
  .user-inputText {
    font-size: 1em !important;
    width: 100% !important;
  }
  .user-formGroup {
    width: auto !important;
    display: flex !important;
    width: 100%;
    min-width: auto;
    align-items: flex-start;
    margin: 3vw 0 0px 0 !important;
  }
  .subtitle-data-txt {
    justify-content: flex-start;
    font-size: 0.8em;
    text-align: center;
  }

  .user-form-section {
    padding: 50px 32px 20px 32px !important;
  }

  .recovery-btn {
    font-size: 0.7em !important;
    margin-right: 5px !important;
  }
  .p-scrollpanel-content {
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 570px) {
  label {
    min-width: auto;
  }
  .user-formGroup2 {
    width: auto !important;
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    min-width: auto;
    align-items: flex-start;
    margin: 5px 0 !important;
    gap: 5px !important;
  }
  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
  }
  .user-inputText {
    min-width: auto;
  }
  .half-screen {
    margin-left: 0px !important;
  }
}
