.casetype-wrapper {
  height: 100vh;
  width: 100%;
  padding: 2vw 30px 30px 30px;
  display: flex;
  justify-content: center;
}

.casetype-wrapper2 {
  height: 100%;
  width: 100%;
  padding: 60px 20px 20px 20px;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
}

.engine-bagde {
  background-color: gray;
  color: white;
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 0.7em;
}
.casetype-body {
  display: flex;
  flex-direction: column;
}
.groupParams {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  padding: 5px;
  gap: 10px;
  background-color: #f1f4f5;
  border-radius: 8px;
}

.groupParams2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
  gap: 10px;
  background-color: #5dc8db;
  border-radius: 8px;
}

.subheader-casetype {
  min-width: 200px;
}

.subheader-casetype2 {
  min-width: 200px;
}

.groupParams2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
  gap: 10px;
  background-color: #f1f4f5;
  border-radius: 8px;
}
.paramsExpected {
  height: auto !important;
  background-color: #f1ddca !important;
  padding: 6px 12px !important;
  border-radius: 8px !important;
  border: rgba(0, 128, 0, 0.119) solid 1px;
  color: #282828 !important;
  font-size: 0.7em !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.paramsExpected:hover {
  background-color: #f1ddcaaf !important;
  border-radius: 8px;
}

.flexer {
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 10px;
}

.flexer2 {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 500;
  gap: 10px;
}

.resultExpected {
  height: auto !important;
  margin: 0 !important;
  background-color: #eadee7 !important;
  padding: 6px 12px !important;
  border-radius: 8px !important;
  /* border: rgba(0, 128, 0, 0.119) solid 1px; */
  color: #282828 !important;
  font-size: 0.7em !important;
  font-weight: 600 !important;
}

.statesExpected {
  height: auto !important;
  background-color: #caf1e4 !important;
  padding: 6px 12px !important;
  border-radius: 8px !important;
  border: rgba(0, 128, 0, 0.119) solid 1px;
  color: #282828 !important;
  font-size: 0.7em !important;
  margin: 0 !important;
  height: auto !important;
  font-weight: 600 !important;
}

.statesExpected:hover {
  background-color: #e7f8f2 !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
}

.resultExpected:hover {
  background-color: #eadee798 !important;
  padding: 6px 12px !important;
  border-radius: 8px;
}

.add-caseTypeDetail {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background-color: rgba(151, 144, 144, 0.116);
  color: white;
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.8em;
  border: rgba(255, 0, 68, 0.141) dashed 1px;
  width: 100%;
  border-radius: 8px;
}

.add-caseTypeDetail:hover {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background-color: rgba(151, 144, 144, 0.116);
  color: white;
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.8em;
  border: rgba(255, 0, 68, 0.99) solid 1px;
  width: 100%;
  border-radius: 8px;
}
.none-params {
  padding: 10px;
  background-color: #797b7c1b;
  color: rgba(0, 0, 0, 0.587);
  margin: 0 0;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  font-size: 0.8em;
  border: #22384d55 dashed 2px;
  width: 100%;
  border-radius: 8px;
}
.none-params:hover {
  border: #12202d55 dashed 2px;
}

@media screen and (max-width: 1200px) {
  .subheader-casetype2 {
    min-width: auto;
  }
  .none-params {
    padding: 10px;
  }

  .casetype-wrapper2 {
    max-height: 80vh;
    width: 100%;
    padding: 60px 20px 20px 20px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 570px) {
  .casetype-wrapper {
    padding: 10px 10px 50px 10px;
  }
}
