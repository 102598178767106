#appName {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
  z-index: 2;
  padding: 0.2rem 0;
  gap: 10px;
}

#appIcon {
  width: 40px;
  width: 40px;
}

.appname-container {
  display: inline-block;
  color: white;
}

.app-name__icon {
  margin-top: 4px !important;
  margin-left: 4px !important;
  width: 20px !important;
  height: 20px !important;
}

.app-name__icon2 {
  margin-top: 4px !important;
  width: 20px !important;
  height: 20px !important;
}

@media screen and (max-width: 900px) {
  .app-name__icon {
    margin-top: 15px !important;
    margin-left: 5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  #appName {
    flex-direction: row;
    margin-left: 0px;
  }
}
