.add-app-section {
  display: flex;
  flex-direction: column;
  padding: 60px 30px 20px 30px !important;
  text-align: left !important;
  min-width: 380px;
  background-color: #414f5c !important;
}

.inputs-label {
  color: white !important;
  margin-top: 16px !important;
  margin-bottom: 6px !important;
}

.engines-dropdown {
  width: 100% !important;
  max-height: 40px !important;
  border-radius: 8px !important;
  color: black !important;
  background-color: #3e678e !important;
}

.new-app-container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  justify-content: center;
}

.app-form-inputText {
  height: 45px;
  width: 85%;
  margin-left: auto !important;
  color: #495057;
  background: #ffffff;
}

.app-form-inputText:disabled {
  background-color: white !important;
}

.app-form-button {
  background-color: #3e678e !important;
  min-width: 100px !important;
  padding: 20px !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

@media screen and (max-width: 900px) {
  .add-app-section {
    padding: 45px 30px 30px 30px !important;
    min-width: auto;
  }

  .new-app-container {
    font-size: 0.9em;
  }
}
