.collection-wrapper {
  height: calc(100vh - 230px);
  width: calc(100vw - 300px);
  margin: 20px 0;
  margin-top: 30px;
  box-shadow: 2px 2px 7px #0129398a;
  background-color: #efefef;
  max-width: 1280px;
}

@media screen and (max-width: 1400px) {
  .collection-wrapper {
    height: calc(100vh - 230px);
    width: 100%;
    margin: 20px 20px;
    margin-top: 30px;
    margin-left: 0;
    box-shadow: 2px 2px 7px #0129398a;
    background-color: #efefef;
  }
}

@media screen and (max-width: 900px) {
  .collection-wrapper {
    height: calc(100vh - 190px);
    width: calc(100vw - 105px);
    margin: 1rem 0;
    margin-top: 30px;
    margin-left: 0px;
    box-shadow: none;
  }
}
