.setting-details {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
}

.setting-details-close {
  display: flex;
  justify-content: flex-end;
}

.save-btn {
  background-color: transparent !important;
  margin-top: 30px !important;
  color: black !important;
}

.settings-act-container {
  position: absolute;
  top: 10px;
  right: 50px;
  z-index: 100000000000;
}

.setting-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #bcbcbc22;
  padding: 10px 10px;
  width: 100%;
  border-radius: 8px;
}

.settings-flex {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
}

.team-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.close2 {
  position: relative;
  height: 35px !important;
  width: 35px !important;
  cursor: pointer !important;
  margin-top: 20px !important;
  margin-right: 15px !important;
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.close2:after {
  content: 'x';
  margin-right: 7px !important;
  height: 20px !important;
  width: 20px !important;
  background-color: transparent !important;
  color: black !important;
}

.setting-details-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 40px;
  color: black;
  height: calc(100% - 50px);
  width: 100%;
  background-color: #414f5c;
}

.setting-details-picker {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.setting-details-dragger {
  background-color: #414f5c !important;
  padding: 35px 30px 20px 30px;
  min-width: 600px;
}

.loader-clss {
  height: 40vh;
}

.member-info {
  padding: 10px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: whitesmoke;
  border-radius: 0px !important;
}

.wrapper-selected {
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper-end {
  justify-content: flex-end;
}

.setting-details-color-txt {
  color: white;
}

.setting-details-black-txt {
  color: white;
  margin: 0 !important;
}

.setting-details-black-header {
  font-size: 0.9em;
  color: white;
  margin: 2px 10px !important;
  font-weight: 800;
}

.team-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
  margin: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
}

.edit-app-btn {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.setting-details-profile-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 450px;
  justify-content: center;
  margin: 30px 0;
}

.setting-details-profile {
  height: 100px;
  padding: 20px;
}

.setting-details-profile-2 {
  height: 22vh;
  width: 100%;
  margin-left: 0px;
}

@media screen and (max-width: 900px) {
  .setting-details-profile-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 450px;
    justify-content: center;
    margin: 60px 0 20px 0;
  }

  .settings-flex {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .setting-container {
    margin-top: 40px;
  }
  .setting-details-color-txt {
    display: none;
  }

  .setting-details-profile-2 {
    display: none;
  }

  .setting-details-close {
    display: flex;
    justify-content: flex-end;
  }

  .setting-details-content {
    height: calc(100% - 50px);
    margin-top: 0 !important;
  }

  .close2 {
    margin-top: 10px !important;
    margin-right: 5px !important;
  }

  .wrapper-selected {
    height: 40px;
  }

  .setting-details-profile {
    height: 70px;
    margin-top: 30px;
  }

  .setting-details-dragger {
    padding: 20px 10px;
    min-width: auto;
  }

  .setting-details-picker {
    padding-left: 10px;
  }

  .team-title {
    margin-right: 10px;
  }
}
