.staff-app {
  border-style: solid;
  border-color: #2b8ae3;
  border-width: 5px;
  margin-top: 1.5rem;
}

.staff-app-header-figure {
  padding: 4%;
}

.staff-app-owner-figure {
  padding: 3%;
}

.staff-app-data-figure {
  padding: 2%;
}

.staff-app-section {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: rgba(95, 158, 160, 0.503);
  justify-content: center;
  align-items: center;
  margin-left: .5rem;
}

.staff-app-content {
  gap: 1rem;
  margin-bottom: 1rem;
}

.staff-app-icon {
  width: 80%;
}

.staff-app-header {
  margin-left: 3rem;
}

.staff-app-title {
  font-size: 1.5em;
}

.staff-app-owner-title {
  margin-top: 15rem;
}

.staff-app-data-title {
  font-size: 0.9em;
}

.staff-app-data-num {
  font-size: 0.7em;
}