.error-txt2 {
  display: flex;
  justify-content: flex-start;
  color: #bb5943e6;
  font-size: 0.9em;
  gap: 8.33px;
  margin-top: -2px;
  margin-bottom: -10px;
  line-height: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding-left: 10px;
  margin-left: auto;
}
