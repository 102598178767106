.login-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 22vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #192d4094;
  padding: 1.5rem;
  height: auto;
  border-radius: 15px;
  min-width: auto;
}

.loading {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.ticket-action-container {
  position: absolute;
  bottom: 17px;
  right: 18px;
}

.tickets-modelu-btn {
  background-color: transparent !important;
  color: black !important;
  gap: 10px !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 30px !important;
  margin-bottom: 1px !important;
  margin-right: 10px !important;
}

.ticket-icon {
  color: white !important;
}

.tickets-modelu-btn:hover {
  color: white !important;
}

.ticket-title {
  font-size: 14px;
  font-weight: 330;
}

.logins-container {
  display: flex;
}

.ticket-number {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  padding: 2.5px 6px;
  background-color: #f04;
  font-size: 12px;
  color: white;
  margin-top: -12px;
  margin-right: 10px;
  cursor: default;
}

.long-number {
  padding: 2.5px 5px;
  border-radius: 12%;
}

.bye-txt {
  font-size: 1.2em;
  height: 100px;
  color: whitesmoke;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@media screen and (max-width: 900px) {
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .wrapper {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 570px) {
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 20vh;
  }
  .wrapper {
    flex-direction: column;
  }

  .tickets-modelu-btn:hover {
    background-color: transparent !important;
    color: black !important;
    gap: 10px !important;
  }

  .ticket-icon {
    color: rgba(31, 30, 30, 0.699);
  }
}
