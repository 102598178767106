.flex-left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.settings-cls {
  background: transparent !important;
  margin: 0 !important;
  height: 28px !important;
}

.settings-cls:hover {
  background: transparent !important;
}

.hide-sidebar {
  position: absolute;
  top: 70px;
  right: 10px;
  background-color: #3e678ec7 !important;
  border-radius: 50px !important;
}

.hide-sidebar:hover {
  border-radius: 50px !important;
  background-color: #f04 !important;
  opacity: 0.8 !important;
}

.hide-sidebar-extended {
  position: absolute;
  background-color: #3e678ec7 !important;
  border-radius: 50px !important;
  top: 70px;
  right: calc(20vw + 10px);
}

.hide-sidebar-extended:hover {
  border-radius: 50px !important;
  background-color: #f04 !important;
  opacity: 0.8 !important;
}

.builder-template-wrapper {
  height: 100%;
  width: 100%;
}
