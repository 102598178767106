.recovery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.update-img {
  margin: 5vh auto 2vh auto;
  width: 250px;
  background-color: rgba(128, 128, 128, 0.215);
  padding: 20px;
  border-radius: 30px;
}

.pwd-input {
  min-height: 45px;
  border-radius: 8px;
  width: 100%;
  font-size: 1em;
  padding-left: 20px !important;
  margin: 0 auto;
  margin-bottom: 20px !important;
  background: white !important;
}

.pwd-error {
  margin-top: -15px;
  margin-bottom: 20px;
  margin-left: 10px;
  color: red;
  text-align: start;
  max-width: 364px;
}

.update-pwd-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0px;
}

.recovery-cancel {
  background-color: transparent !important;
  color: #22384d !important;
  padding: 20px !important;
}

.recovery-cancel:hover {
  color: white !important;
}

.recovery-primary {
  padding: 20px !important;
  margin-right: 0 !important;
}

.keep-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.name-txt {
  font-family: 'Elbony', sans-serif;
  color: black !important;
}

.disclosure-txt {
  text-align: center;
  font-size: 1.2em !important;
  color: #22384d;
  max-width: 450px !important;
  margin: 0px 10px 40px 10px;
}

@media screen and (max-width: 900px) {
  .update-img {
    margin: 30px auto 30px auto;
    width: 40vw;
    background-color: rgba(128, 128, 128, 0.215);
    padding: 20px;
    border-radius: 30px;
  }
}

@media screen and (max-width: 570px) {
  .update-img {
    margin: 30px auto 30px auto;
    width: 50vw;
    background-color: rgba(128, 128, 128, 0.215);
    padding: 20px;
    border-radius: 30px;
  }
}
