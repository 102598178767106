.loader-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  min-width: 350px;
}

.noBackground {
  background-color: transparent !important;
}

.bg-transparent {
  height: 100%;
  width: 100%;
}

.loader-container-adjusted {
  height: 100%;
  width: 100%;
}

.building-blocks {
  position: relative;
}

@-webkit-keyframes building-block {
  0%,
  20% {
    opacity: 0;
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  90%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
  }
}

@keyframes building-block {
  0%,
  20% {
    opacity: 0;
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  90%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
    background-color: #6f7a88;
    border-radius: 50%;
  }
}

.building-blocks div {
  height: 20px;
  position: absolute;
  width: 20px;
}

.building-blocks div:after {
  -webkit-animation: building-block 2.1s ease infinite backwards;
  animation: building-block 2.1s ease infinite backwards;
  background: var(--primary);
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
}

.building-blocks div:nth-child(1) {
  -webkit-transform: translate(-50%, -50%) translate(60%, 120%);
  transform: translate(-50%, -50%) translate(60%, 120%);
}

.building-blocks div:nth-child(2) {
  -webkit-transform: translate(-50%, -50%) translate(-60%, 120%);
  transform: translate(-50%, -50%) translate(-60%, 120%);
}

.building-blocks div:nth-child(3) {
  -webkit-transform: translate(-50%, -50%) translate(120%, 0);
  transform: translate(-50%, -50%) translate(120%, 0);
}

.building-blocks div:nth-child(4) {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.building-blocks div:nth-child(5) {
  -webkit-transform: translate(-50%, -50%) translate(-120%, 0);
  transform: translate(-50%, -50%) translate(-120%, 0);
}

.building-blocks div:nth-child(6) {
  -webkit-transform: translate(-50%, -50%) translate(60%, -120%);
  transform: translate(-50%, -50%) translate(60%, -120%);
}

.building-blocks div:nth-child(7) {
  -webkit-transform: translate(-50%, -50%) translate(-60%, -120%);
  transform: translate(-50%, -50%) translate(-60%, -120%);
}

.building-blocks div:nth-child(1):after {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.building-blocks div:nth-child(2):after {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.building-blocks div:nth-child(3):after {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.building-blocks div:nth-child(4):after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.building-blocks div:nth-child(5):after {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.building-blocks div:nth-child(6):after {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.building-blocks div:nth-child(7):after {
  -webkit-animation-delay: 1.05s;
  animation-delay: 1.05s;
}

@-webkit-keyframes fed {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0) scale(2.5);
    transform: translateX(0) scale(2.5);
  }

  40%,
  60% {
    -webkit-transform: translateX(0) scale(1.5);
    transform: translateX(0) scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(500%);
    transform: translateX(500%);
  }
}

@keyframes fed {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0) scale(2.5);
    transform: translateX(0) scale(2.5);
  }

  40%,
  60% {
    -webkit-transform: translateX(0) scale(1.5);
    transform: translateX(0) scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(500%);
    transform: translateX(500%);
  }
}

@keyframes feed {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
  }

  40% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(500%);
    transform: translateX(500%);
  }
}

.feeder {
  height: 20px;
  position: relative;
  width: 20px;
}

.feeder div {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: fed;
  animation-name: fed;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-delay: calc(var(--delay) * 1s);
  animation-delay: calc(var(--delay) * 1s);
  background-color: var(--primary);
  border-radius: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.feeder div:nth-child(2),
.feeder div:nth-child(3) {
  -webkit-animation-name: feed;
  animation-name: feed;
}

.feeder div:nth-child(2) {
  --delay: 0.25;
}

.feeder div:nth-child(3) {
  --delay: 0.5;
}

.loader {
  font-size: 15px;
  min-width: 100px;
  min-height: 100px;
  margin-top: -50px;
  border-radius: 10%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.6s infinite ease;
  transform: translateZ(0);
}

.loader2 {
  font-size: 0.5px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  position: relative;
  text-indent: -999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  margin-left: 10px;
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}

@media screen and (max-width: 570px) {
  .loader {
    min-width: 60px;
    min-height: 60px;
    margin-top: -50px;
  }

  .loader-container {
    min-width: 100px;
  }

  .building-blocks div {
    height: 10px;
    position: absolute;
    width: 10px;
  }

  .building-blocks div:after {
    -webkit-animation: building-block 2.1s ease infinite backwards;
    animation: building-block 2.1s ease infinite backwards;
    background: var(--primary);
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    background-color: rgb(255, 255, 255);
  }
}
