.user-engine-box {
  min-width: 200px;
  min-height: 20vh;
  max-height: 130px;
  box-shadow: 2px 2px 7px #01293917 !important;
  border-radius: 10px;
  background-color: rgb(224, 236, 246) !important;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.user-engine-drag-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-engine-drag-list {
  margin: 0 !important;
  margin-top: 8px !important;
  list-style: none !important;
  padding-left: 10px !important;
  height: 100% !important;
}

.user-engine-angle-icon {
  font-size: 1.5em !important;
}

.user-engine-drag-button {
  height: 30px !important;
  width: 25px !important;
  padding: 2px !important;
  margin: 0 !important;
}

.u-e-drag-button-section {
  padding: 0.5rem;
  height: 100%;
}

.user-engine-item {
  cursor: pointer;
  margin: 0 !important;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-engine-item:hover {
  color: #f04;
}

.user-engine-selectedItem {
  cursor: pointer;
  font-weight: 500;
  color: #f04;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-engine-selectedItem:hover {
  font-size: 0.9em;
}

.user-engine-titles {
  justify-content: space-between;
}

.dragger-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
}

.dragger-container {
  display: flex;
  gap: 10px;
  padding: 10px 0px 15px 0px;
}

.action-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: -60px;
  z-index: 1000000;
}

.user-subtitle-form {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 5px !important;
  margin-left: 5px;
  color: white;
}

@media screen and (max-width: 900px) {
  .action-footer {
    position: inherit;
    bottom: 4.8vh;
    left: -5px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }
  .user-engine-drag-list {
    padding-left: 5px !important;
    margin-top: 5px !important;
  }

  .user-engine-drag-section {
    flex-direction: column;
  }

  .user-engine-angle-icon {
    font-size: 1.2em !important;
  }

  .user-engine-box {
    border: 2px solid #6466685e;
    border-radius: 10px;
    max-height: 100px;
  }

  .user-subtitle-form {
    font-size: 0.9em;
  }
  .action-footer {
    position: inherit;
    bottom: 3.3vh;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 570px) {
  .user-subtitle-form {
    font-size: 0.8em;
    margin: 0 !important;
  }
  .user-engine-item:hover {
    font-size: 0.8em;
  }

  .user-engine-selectedItem {
    cursor: pointer;
    font-weight: 500;
    font-size: 0.8em;
    color: #f04;
    margin: 0 !important;
  }

  .user-engine-box {
    min-width: 30vw;
    max-width: 32vw;
    width: auto;
    margin-top: 10px;
  }
}
