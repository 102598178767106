.app-card-wrapper {
  width: 230px;
  border-radius: 20px;
  background-color: rgb(245, 241, 241);
  box-shadow: 2px 2px 7px #0129398a !important;
}

.app-card-header {
  display: flex;
  width: 100%;
}

.app-id {
  font-size: 9px !important;
  background-color: rgba(127, 127, 127, 0.095);
  border-radius: 8px;
  padding: 4px;
  margin-top: 2px;
  margin-right: 10px;
  max-width: 150px;
}

.app-card-header-left {
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  background-color: #efef;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0 0 0 0;
  filter: blur(0.5px);
}

.app-card-header-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 0px 10px 0px 10px;
}

.app-card-header-right-up {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-right-up-name {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.6em;
  color: #22384d;
}

.app-card-header-right-down {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.header-right-down-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.app-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 3px;
  padding: 10px 0px 10px 10px;
}

.app-card-body-up {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 25px;
  padding: 0;
}

.app-card-body-down {
  display: flex;
  justify-content: left;
  width: 100%;
  padding: 0;
}

.app-card-body-down-description {
  max-width: 240px;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 9px;
  font-weight: 300;
}

.app-card-body-left {
  width: 75%;
}

.app-card-body-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  align-items: center;
  margin-bottom: -20px;
}

.app-card-footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.2rem !important;
}

.button-useropt-image {
  height: 25px !important;
  width: 25px !important;
  padding: 3px;
}

.button-useropt-image2 {
  height: 20px !important;
  width: 20px !important;
  padding: 3px;
}

.user-opt-button {
  display: flex;
  justify-content: center !important;
  margin: 0 !important;
  padding: 0 !important;
  min-width: 25px !important;
  min-height: 25px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.user-opt-button2 {
  display: flex;
  justify-content: center;
  margin: 0 0.2rem !important;
  padding: 0 !important;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 50% !important;
  background-color: rgb(213, 213, 33) !important;
}

.user-opt-button3 {
  display: flex;
  justify-content: center;
  margin: 0 0.2rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  background-color: transparent !important;
}

.app-card-footer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 0.1rem;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #3e678e;
}

.app-card-item {
  margin: 0;
  font-weight: 345;
  font-size: 0.8em;
}

.app-card-subtitle {
  font-size: 0.5em;
  font-weight: 500;
  margin-bottom: 1px;
}

.delete-cls {
  width: 100%;
}

.delete-icon-btn {
  border: none !important;
  background: transparent;
  padding: 0;
  cursor: pointer;
  margin-right: 40px !important;
}
.delete-icon-btn {
  border: none !important;
  background: transparent;
  padding: 0;
  cursor: pointer;
  margin-right: 40px !important;
}

.delete-icon-btn img {
  width: 25px !important;
  height: 25px !important;
}

.delete-icon-btn:focus {
  border: none !important;
}

@media screen and (max-width: 900px) {
  .app-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1vw 0.5vw 2vw;
  }
  .app-card-wrapper {
    min-width: 26px;
  }
}
