.dashboard-charts-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.dashboard-charts-wrapper .p-card {
  margin: 0 !important;
}

@media screen and (max-width: 900px) {
  .dashboard-charts-wrapper {
    gap: 10px;
  }
}

@media screen and (max-width: 570px) {
}
