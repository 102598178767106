.app-form-section {
  padding: 60px 20px 20px 20px !important;
}

.app-form-section-2-columns {
  padding: 20px !important;
  columns: 2;
}

.app-form-section {
  padding: 60px 20px 20px 20px !important;
}

@media screen and (max-width: 570px) {
  app-form-section-2-columns {
    columns: 1;
  }
}

.app-formGroup {
  width: 100%;
  justify-content: left;
  align-items: left;
  padding-bottom: 20px;
}

.app-form-inputText {
  width: 85%;
  margin-left: auto !important;
}

.app-form-button {
  padding: 20px !important;
  margin-left: auto !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

label {
  font-weight: 500;
  padding-right: 2%;
}

.custom-data {
  white-space: pre !important;
}
