.template-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(216, 206, 206, 0.036);
  height: 90vh;
  width: 100%;
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.202) !important;
}
