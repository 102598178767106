.modeler-iframe-wrapper {
  height: 100%;
  width: 100%;
}

.modeler-iframe-container {
  height: calc(86%);
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
}

.modeler-iframe {
  height: 100%;
  width: 100%;
}

.modeler-iframe-iframe {
  height: 100%;
  background-color: #efefef;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.lan-inputText {
  background-color: red;
  width: 32vw !important;
  height: 100px;
}

.lan-inputText:disabled {
  background-color: rgba(159, 199, 194, 0.389) !important;
  border-bottom: red solid 1px !important;
  color: #3e678e !important;
}

.modeler-iframe-iframe::-webkit-scrollbar {
  display: none;
}
.modeler-iframe-header {
  margin-bottom: 10px;
}

.load-flow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  margin-left: 120px;
}

@media screen and (max-width: 900px) {
  .modeler-iframe-wrapper {
    padding: 0rem;
  }

  .modeler-iframe-header {
    margin: 0rem;
  }

  .modeler-iframe-container {
    margin: 0rem;
    height: 88vh;
    box-shadow: none;
    border-radius: 0px;
  }

  .modeler-iframe {
    height: 100%;
  }

  .modeler-iframe-iframe {
    height: 100%;
  }
}

@media screen and (max-width: 570px) {
  .load-flow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: 0px;
  }
  .modeler-iframe-container {
    height: calc(100% - 180px);
    margin: 0 0 0 -20px;
    border-radius: 0px;
  }
}
