.create-template-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.create-template-body {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.create-template-empty {
  display: none;
}

.layout {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid:
    'header header header' auto
    'leftSide body rightSide' 1fr
    / auto 1fr auto;
  gap: 0px;
}

.header {
  grid-area: header;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
  background: rgb(34, 56, 77);
  background: linear-gradient(125deg, rgba(34, 56, 77, 1) 60%, rgba(62, 103, 142, 1) 130%);
}
.leftSide {
  grid-area: leftSide;
  background: rgba(34, 56, 77, 0.05);
  width: 20vw;
}
.body {
  grid-area: body;
  background: rgba(34, 56, 77, 0.1);
  width: 100%;
}
.rightSide {
  grid-area: rightSide;
  background: rgba(34, 56, 77, 0.1);
  width: 20vw;
}

@media screen and (max-width: 1450px) {
}

@media screen and (max-width: 1024px) {
  .create-template-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .create-template-body {
    display: none;
  }
  .create-template-empty {
    text-align: center;
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    gap: 20px;
    color: white;
    justify-content: center;
    align-items: center;
    background: rgb(34, 56, 77) !important;
    background-image: url('https://cdn.houndsoftware.net/assetstmp/clouds.png') !important;
    background-position: 10% 30% !important;
    padding: 20px;
  }
  .create-text {
    max-width: 300px;
  }
}

@media screen and (max-width: 570px) {
}
