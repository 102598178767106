.main-dashboard-wrapper {
  height: 100%;
  width: 100%;
}

.main-dashboard-filter {
  height: 90px;
  width: 100%;
}

.main-dashboard-body {
  display: flex;
  height: 100%;
  width: calc(100vw - 190px);
}

.main-dashboard-body-ext {
  display: flex;
  height: 100%;
  width: calc(100vw);
}

.main-dashboard-body-left {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 65%;
  padding: 2vw 10vw 10px 10vw;
}

.main-dashboard-body-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 65%;
  padding: 1vw 50px 80px 50px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: flex-start;
  background-color: rgba(128, 128, 128, 0.04);
  padding-bottom: 70px;
}

.main-dashboard-body-right::-webkit-scrollbar {
  display: none;
}

.wrapper-filters {
  display: flex;
  justify-content: flex-end;
  overflow: hidden !important;
  margin-right: 0px !important;
  margin: 5px 0px 20px 20px !important;
}

.range-picker {
  min-width: 215px !important;
  height: 32px !important;
  border: none !important;
  margin-right: 0 !important;
}

.info-space {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.info-space-A {
  display: flex;
  gap: 2vw;
  flex-direction: row;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  padding: 0 0px 0 3vw;
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.056) !important;
}

.info-space-B {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 3rem;
  width: calc(100vw - 260px) !important;
  gap: 20px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: flex-start;
}

.info-space-B::-webkit-scrollbar {
  display: none;
}

.tooltip-box {
  padding: 15px;
  background: #667482;
  color: white;
  border-style: solid;
  background-color: rgb(107, 107, 108);
  border-width: 3px;
}

.tooltip-label {
  font-size: 12px;
  font-weight: 500;
}

.tooltip-value {
  font-size: 16px;
  font-weight: 600;
}

.box {
  width: 100%;
  padding: 1rem 0 0 1rem;
}

.box2 {
  padding-top: 5vh;
  min-height: 300px;
}

.box-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.box-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.box-down::-webkit-scrollbar {
  display: none;
}

.global-left {
  display: flex;
  flex-direction: column;
  animation: fadeIn 5s;
}

.lines-right-top {
  display: flex;
  width: 100%;
  height: 90px;
}

.lines-right-container-top-A {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.case-name {
  color: white;
  margin-left: 10px !important;
  margin-right: 0px !important;
  margin-top: 12px;
  height: 30px;
}

.lines-right-container-top {
  padding: 15px;
  box-shadow: 2px 2px 7px #0129398a;
  background-color: rgba(255, 255, 255, 0.867);
  height: 122px;
  width: 100%;
  border-radius: 5px;
  border-top-left-radius: 0px;
}

.lines-right-container-bottom {
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 7px #0129398a;
  background-color: rgba(255, 255, 255, 0.867);
  padding: 15px;
  margin-top: 16px;
  height: 220.5px;
  width: 100%;
  border-radius: 5px;
  border-top-left-radius: 0px;
}

.totalCases-text {
  margin: 0;
  text-align: center;
}

.totalCases-value {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}

.global-text {
  margin: 0;
  text-align: center;
  font-size: 15px;
}

.avgResolution-value {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}

#global {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 10px;
}

#global::-webkit-scrollbar {
  display: none;
}

#messages {
  display: flex;
  width: auto;
  gap: 20px;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  transition: width 2s;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-right: 0vw;
  padding: 0 0px 0 40px;
}

#messages::-webkit-scrollbar {
  display: none;
}

.logsTable {
  margin-top: 18px;
  padding: 15px;
  height: 34vh;
  box-shadow: 2px 2px 7px #0129398a;
  background-color: rgba(255, 255, 255, 0.867);
  border-radius: 5px;
}

.apexcharts-treemap-rect {
  width: 100%;
  height: 100%;
}

.filter-toggler {
  box-shadow: none !important;
  width: 70px !important;
  height: 30px !important;
  padding: 0 15px 0 17px !important;
}

.direction {
  margin-left: -9px !important;
}

@media screen and (max-width: 1350px) {
  .box2 {
    padding-top: 5vh;
    width: 100%;
  }
  .main-dashboard-body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .main-dashboard-body-ext {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .main-dashboard-body-ext::-webkit-scrollbar {
    display: none;
  }

  .main-dashboard-body::-webkit-scrollbar {
    display: none;
  }

  .main-dashboard-body-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    padding: 3.5rem 0 0 0;
  }

  .main-dashboard-body-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    padding: 30px 0 30vh 0;
  }

  .box {
    width: 78%;
    padding: 10px 30px;
  }

  .box2 {
    width: 78%;
    padding: 10px 30px;
  }

  #global {
    display: flex;
    justify-content: flex-end;
    height: auto;
    margin: 0;
    overflow-x: scroll !important;
  }

  .main-dashboard-wrapper {
    padding: 0rem;
  }

  .lines-right {
    gap: 20px;
    height: 100%;
    width: 100%;
  }

  .lines-right-container {
    display: flex;
    flex-direction: row;
    width: 70%;
  }

  .lines-right-container-top {
    background-color: rgba(255, 255, 255, 0.867);
    padding: 15px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .lines-right-container-top-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 45%;
    padding: 15px;
    height: 111%;
  }

  .lines-right-container-top-B {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    padding: 15px;
    height: 111%;
  }

  .lines-right-container-bottom {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: rgba(255, 255, 255, 0.867);
    padding: 15px;
    margin: 0;
    height: 171px;
    width: 100%;
    border-radius: 10px;
  }

  .lines-right {
    display: flex;
    flex-direction: column;
    margin: 10px 0rem 0px 40px;
    height: 46%;
    width: calc(100% - 70px);
  }

  .range-picker {
    transition: width 2s;
  }

  .info-space {
    justify-content: space-between;
  }

  .info-space-B {
    width: calc(100vw - 250px) !important;
    display: flex;
    margin-left: 30px;
    padding-top: 3px;
    gap: 20px;
  }

  #messages {
    justify-content: flex-end;
  }

  .wrapper-filters {
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 900px) {
  .main-dashboard-body {
    width: calc(100vw - 68px);
  }
  .box {
    width: 80%;
    padding: 0;
  }
  .main-dashboard-body-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(60% + 5vmax);
    padding: 5vw 0 0 0;
    padding-bottom: 100px;
  }

  .main-dashboard-body-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
  }
  .info-space-A {
    display: flex;
    height: 65px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 0px;
  }

  .loader-dashboard-chart {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .info-space-A::-webkit-scrollbar {
    display: none;
  }
  .wrapper-filters {
    margin-right: 0px !important;
  }

  .info-space-B {
    display: flex;
    width: calc(100vw - 112px) !important;
  }

  .box-up {
    flex-direction: column;
    gap: 5px;
    margin-top: 0px;
  }

  .box-down {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #messages {
    justify-content: flex-end;
    height: auto;
    gap: 5px;
    padding: 0 0px 0 0px;
  }
}

@media screen and (max-width: 570px) {
  .box {
    width: calc(90% - 5vw);
    padding: 0px;
  }
  #messages {
    justify-content: flex-start;
  }
  .lines-right-container {
    height: 100%;
    width: 100%;
  }

  .info-space-B {
    display: flex;
    margin-left: 24px;
  }

  .main-dashboard-body-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(70% - 2vmax);
  }

  .range-picker {
    width: 170px;
    border: none !important;
  }

  .lines-right {
    flex-direction: column;
    margin: 0 0 0 10px;
    height: 100%;
    width: calc(100% - 30px);
    gap: 20px;
  }

  .lines-right-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 10px;
  }

  .lines-right-container-top {
    width: 100%;
  }

  .lines-right-container-bottom {
    margin: 0;
    width: 100%;
  }

  .lines-right-container-top-A {
    width: 100%;
    padding: 0;
    height: 105%;
  }

  .lines-right-container-top-B {
    width: 100%;
    padding: 0;
    height: 105%;
  }

  .case-name {
    margin-left: 0px;
  }

  #global {
    margin: 0 10px 0 0;
  }
}
