.action-container {
  display: flex;
  align-items: center;
  max-width: 1180px;
  padding-left: 0px;
}

.back {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  color: #6f7a88 !important;
  margin: 5px 5px 5px 0px !important;
  padding: 10px !important;
}
.back-white {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  color: #ffffff !important;
  margin: 5px 5px 5px 5px !important;
  padding: 17px !important;
}

.back2 {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  color: #6f7a88 !important;
  margin: 5px 15px 5px -10px !important;
  padding: 17px 17px 17px 17px !important;
}

.back2:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: #f04 !important;
}

.back:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: #f04 !important;
}

.back-title {
  font-size: 1.3em;
  margin: 0 0 0 0;
}

.back-title-wh {
  color: white;
  font-size: 1.1em;
  margin: 0 0 0 0;
}

@media screen and (max-width: 1200px) {
  .action-container {
    margin: 0px 15px 0px 0px;
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 900px) {
  .back-title-wh {
    color: white;
    font-size: 0.9em;
    margin: 0 0 0 0;
  }
  .back-white {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 30px !important;
    width: 30px !important;
    color: #ffffff !important;
    margin: 5px 5px 5px 0px !important;
    padding: 17px !important;
  }
  .back-title {
    font-size: 1em;
  }

  .back {
    margin-left: 0px !important;
  }
  .back2 {
    margin-left: 0px !important;
    margin: 5px 5px 5px 1px !important;
  }
}
