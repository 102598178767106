.loginFormGroup {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}

.loginFormGroup:first-child {
  margin-top: 0.1rem;
}

.hr-line {
  border: rgba(255, 254, 254, 0.232) 1px solid;
  width: 100%;
  margin: 20px 0 0 0 !important;
}

.loginInputText {
  padding: 7px 10px 7px 35px !important;
  border: none !important;
  background-color: white !important;
  border-radius: 8px !important;
}

.loginLabel {
  margin-bottom: 5px;
  margin-left: -1px;
  z-index: 100;
  background-color: #f2f2f2;
  width: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  font-weight: 300;
  border-radius: 18px;
  font-family: 'Elbony', sans-serif;
}

.submit-btn {
  background-color: #3e678e !important;
  margin: 1.5rem 0 0 0 !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 0.9em !important;
  color: white !important;
}

.google-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 17px 10px !important;
  width: 100% !important;
  cursor: pointer;
  color: white !important;
  background-color: #22384d !important;
  box-shadow: none !important;
  margin: 0 !important;
  border-radius: 30px !important;
}

.google-btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 17px 10px !important;
  width: 100% !important;
  cursor: pointer;
  color: white !important;
  background-color: #22384d !important;
  box-shadow: none !important;
  margin: 0 !important;
  font-size: 0.8em !important;
  height: 40px !important;
}

.google-btn2:hover {
  color: white !important;
}

.google-btn:hover {
  color: white !important;
  background-color: #f04 !important;
  border-radius: 30px !important;
}

.google-container {
  width: 100%;
  background-color: rgba(137, 137, 137, 0.107);
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin-top: 20px;
}

.google-container:hover {
  background-color: rgba(137, 137, 137, 0.207);
}

.google-icon {
  width: 20px;
  height: 20px;
}

.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: height 5s;
}

@media screen and (max-width: 900px) {
  .loginFormGroup:first-child {
    margin-top: 0;
  }

  .form-login {
    width: 100%;
  }
  .loginInputText {
    border: none !important;
    background-color: white !important;
    border-radius: 8px !important;
  }
}

@media screen and (max-width: 570px) {
  .loginInputText {
    border: none !important;
    background-color: white !important;
    border-radius: 8px !important;
  }
}
