.main-case-container {
  min-height: calc(100vh);
  padding: 5vw;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 1180px;
  margin: 0 auto;
  gap: 3vw;
}

.img-icon {
  background-color: #22384d1a !important;
  border-radius: 8px !important;
  padding: 5px !important;
  height: 40px !important;
}

.container-cases {
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 200px;
}

.container-cases::-webkit-scrollbar {
  display: none;
}

.subtitle-data-txt {
  margin: 0 !important;
}

.slot-wrapper {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin: 0 auto;
  background-color: #b1b1b117;
  padding: 10px 20px 10px 2vw;
  gap: 1vw;
  border: #3e678e0f solid 2px;
  border-radius: 5px;
}

.btn-goto {
  font-size: 0.7em !important;
  height: 20px !important;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.card-batch3 {
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #22384d;
  color: white !important;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 20vh;
  min-width: 20vw;
  border: 1px solid #3e678e18;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.casetype-name {
  color: white;
}

.casetype-value {
  color: white;
}

@media screen and (max-width: 1200px) {
  .slot-wrapper {
    width: 100%;
    max-width: 600px;
  }
}

@media screen and (max-width: 900px) {
  .slot-wrapper {
    width: 100%;
    max-width: 600px;
  }
}

@media screen and (max-width: 570px) {
  .main-case-container {
    padding: 0px;
    padding-top: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 1180px;
    width: 90%;
    margin: 0 auto;
    gap: 10px;
  }
  .slot-wrapper {
    width: 100%;
    max-width: 600px;
  }
}
