.btn-action-steps {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
}

.stepper-wrapper {
  margin: 20px 20px 50px 20px;
}

@media screen and (max-width: 1200px) {
  .stepper-wrapper {
    margin: 20px 20px 50px 0px;
  }
}
@media screen and (max-width: 570px) {
  .stepper-wrapper {
    margin: 20px 0 50px 0;
  }
}
