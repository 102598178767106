#editAppCard {
  width: 100% !important;
  margin-bottom: 20px !important;
}

#appMainData {
  display: flex;
  background-color: rgba(208, 222, 234, 0.702) !important;
  width: 100%;
  margin: 0 auto;
  padding: 2vw;
  border-radius: 10px;
  overflow: hidden;
}

#possibleDefaulters {
  margin-left: 0%;
}

#lblDefaulters {
  margin-top: 5px;
}

#defaulters {
  margin-left: 20%;
}

#timeData {
  display: flex;
  flex-direction: column;
  background-color: #d1d3d8;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  padding: 15px;
}

#timeData label {
  font-weight: 500;
  margin-top: 15px;
}

#timeData h4 {
  margin-top: 0%;
  margin-bottom: 0%;
}

.tag-title {
  min-width: 150px !important;
  margin-bottom: 0 !important;
  /* margin-left: 0px !important; */
  color: #22384d !important;
  font-size: 1.5em !important;
  display: flex !important;
  align-items: flex-start !important;
}

.flex-edit-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}

.tag-value {
  margin-bottom: 0 !important;
  color: black;
}

.edit-row {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 900px) {
  #appMainData {
    display: flex;
    background-color: rgba(208, 222, 234, 0.702) !important;
    width: 80%;
    margin: 0 auto;
    padding: 2vw 2vw;
    border-radius: 10px;
  }
  .tag-title {
    min-width: 100px !important;
    margin-bottom: 0 !important;
    margin-left: 0px !important;
    color: #22384d !important;
    font-size: 1.2em !important;
  }
  .tag-value {
    font-size: 0.9em !important;
  }
}
