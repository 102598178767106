.forms-block {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-top: 0.5vw;
  margin: 0px auto;
}

.action-wrapper {
  display: flex;
  flex-direction: row;
  min-width: 200px;
  justify-content: center;
}

.typeTemplateChip {
  padding: 4px;
  border-radius: 10px;
  text-align: center;
  color: white !important;
  font-size: 800 !important;
}

.qr-code {
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 1450px) {
  .forms-block {
    width: calc(100vw - 300px);
  }
}

@media screen and (max-width: 900px) {
  .forms-block {
    width: calc(100vw - 110px);
    margin: 0 20px;
  }
}

@media screen and (max-width: 570px) {
}
