.recovery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recovery-update-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vmax;
}

.update-btn-primary {
  background-color: #3e678e !important;
  width: 100% !important;
  min-width: 220px !important;
  height: 40px !important;
  margin: 0 !important;
}

.bullet-white {
  list-style: none;
  padding-left: 10px;
  padding: 10px 20px 30px 20px !important;
  color: white !important;
}

.bullet-pwd {
  color: white !important;
  list-style: decimal;
  padding-left: 10px;
  padding: 0px 20px 20px 30px !important;
}

.pwd-input {
  min-height: 45px;
  border-radius: 8px;
  width: 100%;
  font-size: 1em;
  padding-left: 20px !important;
  margin: 0 auto;
  margin-bottom: 20px !important;
  background: white !important;
}

.pwd-error {
  margin-top: -15px;
  margin-bottom: 20px;
  color: red;
  text-align: start;
  max-width: 364px;
}

.update-pwd-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0px;
}

.update-cancel {
  background-color: transparent !important;
  color: white !important;
  padding: 20px !important;
  border: 0.2px solid rgba(255, 255, 255, 0.339) !important;
}

.recovery-cancel {
  color: white !important;
  border: 0.2px solid rgba(255, 255, 255, 0.339) !important;
}

.recovery-cancel:hover {
  color: white !important;
}

.recovery-primary {
  padding: 20px !important;
  margin-right: 0 !important;
}

.keep-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
}

.name-txt {
  font-family: 'Elbony', sans-serif;
  color: white !important;
}

.disclosure-txt {
  text-align: center;
  font-size: 1.2em !important;
  color: #22384d;
  max-width: 450px !important;
  margin: 0px 10px 40px 10px;
}

@media screen and (max-width: 900px) {
  .update-img {
    margin: 30px auto 30px auto;
    width: 40vw;
    background-color: rgba(128, 128, 128, 0.215);
    padding: 20px;
    border-radius: 30px;
  }
  .recovery-update-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1vmax;
    max-width: 80vw;
  }
  .update-cancel {
    width: 100% !important;
  }
}

@media screen and (max-width: 570px) {
  .update-img {
    margin: 30px auto 30px auto;
    width: 50vw;
    background-color: rgba(128, 128, 128, 0.215);
    padding: 20px;
    border-radius: 30px;
  }
  .update-btn-primary {
    background-color: #3e678e !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 40px !important;
  }
}
