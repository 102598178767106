.subscribers-header-table {
  justify-content: space-between;
}

#subscribers-header-li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#subscribers-header-li li:first-child {
  margin-right: 1.5rem;
}

#subscribers-header-li li:last-child {
  margin-left: 1.5rem;
}

#subscribers-header-li li {
  cursor: pointer;
}

.s-header-l-selected::after {
  content: '';
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  border-bottom: 3.5px solid #6fabf3;
}

.subs-table-icons {
  margin-right: 5%;
}

@media screen and (max-width: 1300px){
  .subs-table-icons {
    display: none !important;
  }

  .phone-row{
    margin-left: 40% !important;
  }
}

.subs-row {
    align-items: center !important;
}

.email-row {
  margin-left: -10%;
}