.engine-user-card {
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 8px 0 8px 30px;
  gap: 10px;
}

.value {
  margin-left: 5px;
  font-weight: 400;
}

.card-header-engine-users {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px !important;
  border-radius: 0 !important;
}

.card-header-engine-users h2 {
  margin: 0.5rem 0;
  color: #fff;
}

.engine-user-card .p-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.engine-user-card h3 {
  margin: auto 0 !important;
  color: black;
}

.engine-user-content-wrapper {
  display: flex;
  width: 100%;
  height: auto !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}

.selected {
  box-shadow: 2px 2px 10px #22384d !important;
}

.engine-user-card__statistics-wrapper {
  display: flex;
}

.engine-user-edit-section {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: -48px 21px 0px auto;
}

.data {
  line-height: 25px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 276px;
  margin: 0 !important;
  font-weight: 330 !important;
  font-size: 0.8em !important;
}

.your-account-badge {
  font-size: 0.8em;
  background-color: #3e678e;
  padding: 2px 5px;
  border-radius: 10px;
  width: 100px;
}

.your-account-badge-seleted {
  font-size: 0.9em;
  background-color: rgba(255, 0, 68, 0.518);
  padding: 2px 5px;
  border-radius: 10px;
  width: 100px;
  border: #f04 solid 1px;
}

.editingBtn {
  fill: #3e678e !important;
  margin: 0 !important;
  width: 45px !important;
  height: 45px !important;
  background-color: #3e678e !important;
}

.card-container-seleted {
  display: flex;
  align-items: center;
  width: calc(100% - 0px) !important;
  border-radius: 0px !important;
  margin-right: 0 !important;
  font-size: 400 !important;
  padding: 5px 0 !important;
  background-color: #3e678e !important;
}

.header-title-seleted {
  width: 100%;
  color: #185f7394 !important;
  text-align: start;
}

.card-container:hover {
  opacity: 0.9;
}

.users-cls {
  background-color: #3e678e !important;
}

.users-cls:hover {
  background-color: #3e678e !important;
}

.card-container-seleted:hover {
  width: calc(100% - 0) !important;
}

.header-title {
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  font-weight: 700 !important;
  font-size: 1.1em !important;
}

@media screen and (max-width: 900px) {
  .engine-user-card {
    min-width: 230px;
    width: 230px;
    max-width: 320px;
    min-height: 150px;
    max-height: 300px;
    margin: 0.5rem;
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 2px 2px 7px #0401008a !important;
  }

  .data {
    max-width: 230px;
  }

  .owner-info-wrapper span {
    font-size: 0.8rem;
  }

  .user-opts {
    justify-content: space-between;
  }

  .customSeparator {
    display: none;
  }

  .data {
    line-height: 17px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 8px 0 8px 5%;
    gap: 10px;
  }
}

@media screen and (max-width: 570px) {
  .header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .data {
    max-width: 190px;
  }
}
