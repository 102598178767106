.camera-container {
  position: relative;
  top: 0;
  right: 0;
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 10px;
}

.camera {
  width: 100%;
  top: -100px;
  border-radius: 30px;
}

.loader-camera {
  position: absolute !important;
  top: 20vh !important;
  z-index: 0 !important;
}

.load-camera {
  position: absolute;
  left: 60px;
  top: 0px;
  bottom: 0;
  margin: auto;
}

.camera-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  max-width: 350px;
  height: auto;
  margin-top: 20px;
  border-radius: 0px;
  padding: 0 20px;
}

.disclaimer-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  list-style-type: none;
  padding-left: 0;
}

.hide-loader {
  display: none;
}

.show-loader {
  display: flex;
}

.shot-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dbdee6;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: 0px;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.review-shot {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5vh;
  gap: 30px;
}

.shot-area-description {
  color: #053fa8;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: -10px !important;
  max-width: 290px !important;
}

.shoot-btn {
  background-color: #053fa8 !important;
  border: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  height: 54.36px !important;
  border-radius: 8.7px !important;
  width: 300px !important;
  margin-right: 0 !important;
}

.shoot-btn:hover {
  background-color: #053fa8 !important;
}

.shoot-btn:disabled {
  background-color: #bec6d06a !important;
  opacity: 0.8 !important;
  cursor: default !important;
}

.shoot-txt {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #6f7a88;
  max-width: 305px;
  text-align: center;
  margin: 0;
}

.actions-shoot {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}

.retry {
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  color: #053fa8 !important;
  background-color: transparent !important;
  height: 54.36px !important;
  width: 100px !important;
  padding: 0 20px !important;
  text-align: center !important;
  box-shadow: none !important;
}

.retry:hover {
  color: white !important;
}

@media screen and (max-width: 900px) {
  .shot-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #dbdee6;
    padding: 30px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: 0px;
    margin-top: 40px !important;
    margin-bottom: 0px;
  }

  .load-camera {
    position: absolute;
    left: 125px;
    top: -190px;
    bottom: 0;
    margin: auto;
  }

  .camera-container {
    flex-direction: column;
    width: 65vw;
    height: 100%;
    padding: 0px;
  }

  .disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: 336px;
    max-width: 336px;
    margin-top: -10px;
  }

  .disclaimer-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 35px;
  }

  .shot-area {
    width: 90%;
    height: 100%;
    padding: 0;
    margin-left: 0px;
    margin-top: 35px !important;
    padding: 20px 0 20px 0;
    border-radius: 10px;
  }

  .review-shot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0vh;
    gap: 30px;
  }
}

@media screen and (max-width: 570px) {
  .camera-container {
    width: 85vw;
  }
}