.lines-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 30px 0 0px 0;
  height: 100vh;
  width: calc(100%);
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  overflow: hidden;
  background-color: #f4f4f4;
}

.headings-batch {
  font-size: 1.3em;
  color: #185f7394;
}

.json-container {
  height: 80vh;
  padding: 2vw 20px 20px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 93%;
  margin: 0 auto;
  gap: 10px;
}

.json-container::-webkit-scrollbar {
  display: none;
}

.flexs {
  display: flex;
  align-items: center;
  gap: 20px;
}

.batch-title {
  background-color: #3e678e;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: white;
  justify-content: center;
  padding: 15px 10px 10px 10px;
  font-size: 1.2em;
  font-weight: 700;
  gap: 20px;
}

.refresh-lines {
  background-color: transparent !important;
  margin: 0px !important;
  width: 30px !important;
}

.file-btn {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
  padding: 15px 10px !important;
  color: #22384d !important;
  background-color: #22384d1c !important;
  width: auto !important;
  height: auto !important;
  justify-content: flex-start !important;
  text-align: center !important;
  border-radius: 8px !important;
  margin: 10px 20px !important;
}

.group-lines {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.file-btn:hover {
  color: white !important;
  border-radius: 8px !important;
}

.btn-accordion {
  display: flex;
  justify-content: space-between;
  background-color: transparent !important;
  padding: 0 !important;
  padding-left: 4px !important;
  color: #22384d !important;
}

.btn-accordion:hover {
  background-color: transparent !important;
}
.note-line {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-weight: 500 !important;
  padding: 0 5px;
}

.files-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: #b1b1b136;
  padding: 10px 20px 15px 20px;
  gap: 5px;
  border: #3e678e0e solid 3px;
  border-radius: 5px;
  max-width: 500px;
  margin: 0 auto;
}

.lineBatch {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  padding: 3px 5px 3px 1vw;
  gap: 10px;
  word-wrap: break-word;
}

.lineBatch2 {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  padding: 3px 5px;
  gap: 10px;
  word-wrap: break-word;
}

.lineBatch2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 3px 5px 1vw 1vw;
  gap: 10px;
  word-wrap: break-word;
  background: rgba(105, 131, 131, 0.037);
  border-radius: 8px;
}

.csv-img {
  width: 20px;
  margin-right: 0px !important;
  background-color: transparent !important;
}

.play-img {
  background-color: #5fdf15d8 !important;
  width: 20px !important;
  border-radius: 50% !important;
  padding: 15px !important;
  margin-left: -30px !important;
  margin-right: 30px !important;
  /* display: none !important; */
}

.caseType-txt {
  font-size: 0.8em;
}

.badge-batch {
  display: flex !important;
  height: auto !important ;
  background-color: rgba(255, 0, 68, 0.798) !important;
  color: rgba(255, 255, 255, 0.949) !important;
  border-radius: 4% !important;
  padding: 4px 8px !important;
  margin: 0px !important;
  gap: 10px !important;
  width: auto !important;
}

.badge-batch:hover {
  background-color: #f04 !important;
}

.caseType-cls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  font-size: 0.8em;
  font-weight: 400;
  gap: 10px;
}

@media screen and (max-width: 1200px) {
  .headings-batch {
    font-size: 1em;
  }
}

@media screen and (max-width: 900px) {
  .batch-title {
    flex-direction: row;
  }
  .flexs {
    margin-right: 20px;
  }
  .files-wrapper {
    margin-top: 0px;
  }
  .file-btn {
    padding: 5px 0px !important;
    color: #22384d !important;
    background-color: transparent !important;
    width: auto !important;
    text-decoration: underline !important;
    font-size: 0.7em !important;
  }
  .caseType-cls {
    margin-top: -10px;
    font-size: 0.7em;
    font-weight: 400;
  }
  .lineBatch {
    display: flex;
    padding: 3px 5px 3px 20px;
    gap: 1px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.7em;
  }

  .btn-accordion {
    display: flex;
    justify-content: space-between;
    background-color: transparent !important;
    padding: 0 !important;
  }
  .json-container {
    min-width: 100%;
    margin: 0px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 570px) {
  .json-container {
    max-width: 100%;
    margin: 0px;
    padding: 5vw;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }

  .headings-batch {
    font-size: 1em;
  }
  .batch-title {
    flex-direction: column;
  }
}
