.details-case-wrapper {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.details-case-header {
  height: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}

.details-case-header-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-right: 10px;
  gap: 10px;
}

.details-case-close {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.button-case-display {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  height: 34px !important;
  margin-top: 19px !important;
}

.details-case-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 0px);
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.details-case-content::-webkit-scrollbar {
  display: none;
}

.details-case-hero {
  width: 450px;
  height: 100%;
  padding: 60px 25px 25px 25px;
  overflow: hidden;
  background: #f4f4f4;
  z-index: 1000;
  background-color: #414f5c !important;
}

.hero-hidden {
  height: 0px;
  transition: height 0.15s ease-out;
}

.hero-displayed {
  height: 350px;
  transition: height 0.15s ease-in;
}

.details-case-template-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.details-case-template-wrapper::-webkit-scrollbar {
  display: none;
}

.details-case-template-title {
  font-weight: 500;
  margin-left: 20px;
}

.details-case-template-container {
  height: 100%;
  min-width: 100%;
  transition: width 2s;
}

.goback-action {
  margin-top: 20px !important;
  height: 40px;
  width: 32px;
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.arrow-left {
  height: 32px;
}

.blue-bg {
  width: 40px;
  height: 40px;
}

.close {
  position: relative;
  box-shadow: none !important;
  height: 34px !important;
  width: 33px !important;
  cursor: pointer;
  color: rgb(255, 255, 255) !important;
  margin-top: 17px !important;
  margin-right: 10px !important;
  background-color: #22384d3b !important;
}

.close:before,
.close:after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 20px !important;
  width: 2px !important;
  background-color: rgb(255, 255, 255);
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.button-case-action {
  height: 34px !important;
  width: 34px !important;
  color: #fff !important;
  margin-top: 0px !important;
  background-color: transparent !important;
  gap: 10px;
}

.saveData {
  background-color: #2196f3 !important;
}

.unLock {
  background-color: #f44336 !important;
}

.closed {
  background-color: #4caf50 !important;
}

.button-case-action2 {
  height: 34px !important;
  width: 150px;
  color: #fff !important;
  margin-top: 20px !important;
  background-color: #2196f3 !important;
}

.button-action-share {
  display: absolute;
  background: transparent !important;
  border: none !important;
  padding: 5px !important;
  height: 25px !important;
  max-width: 25px !important;
}

.details-case-th {
  color: whitesmoke;
  font-weight: 500;
  width: 130px;
}

.details-case-th {
  text-align: left;
  padding: 5px;
}

.details-case-td {
  color: whitesmoke;
  text-align: left;
  padding: 9px 0px 9px 9px !important;
  font-size: 0.8em !important;
}

.details-case-td {
  display: flex;
}

.details-case-table {
  border-collapse: collapse;
  width: 100%;
}

.details-case-td:nth-child(even) {
  background-color: rgba(123, 123, 123, 0.016);
}

.setting-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 40px !important;
  height: 0px;
}

.fullscreen-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: none !important;
}

.fullscreen-btn-on {
  position: sticky;
  top: 0;
  right: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

.fullscreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 300000000000 !important;
  background-color: rgba(236, 236, 231);
}

@media screen and (max-width: 1300px) {
  .details-case-template-container {
    transition: width 1s;
  }
}

@media screen and (max-width: 900px) {
  .details-case-hero {
    width: 450px;
    height: 100%;
    padding: 20px 25px 30px 25px;
    overflow: hidden;
    background: #f4f4f4;
    z-index: 1000;
  }
  .arrow-left {
    height: 30px;
  }

  .goback-action {
    margin-top: 20px !important;
    height: 40px;
    width: 30px;
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .hero-displayed {
    height: 450px;
    transition: max-height 0.15s ease-out;
  }

  .fullscreen-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    margin-right: -35px !important;
    margin-top: -15px !important;
    border-radius: none !important;
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 30% !important;
  }

  .button-case-display {
    margin-top: 21px !important;
  }

  .details-case-table {
    margin-left: 0;
    margin-top: 30px;
  }

  .details-case-template-wrapper {
    padding: 1rem;
    overflow: hidden;
  }

  .details-case-header-actions {
    display: flex;
    justify-content: space-around;
    margin-left: 40px;
    margin-right: 10px;
    gap: 5px;
  }

  .button-case-action {
    height: 32px !important;
    width: 32px !important;
    color: rgb(255, 255, 255) !important;
    margin-top: 0px !important;
  }

  .button-case-action2 {
    width: 130px;
    color: rgb(255, 255, 255) !important;
    margin-top: 20px !important;
    font-size: 12px !important;
  }

  .button-action-share {
    height: 20px !important;
    padding-top: 2px !important;
    margin: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-left: 10px !important;
  }

  .close {
    box-shadow: none !important;
    position: relative;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: 10px !important;
    margin-right: 0px !important;
  }

  .close:before,
  .close:after {
    height: 25px;
  }

  .details-case-td {
    align-items: center;
  }

  .details-case-tr {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
  }

  .details-case-template-wrapper {
    margin: 0rem;
    padding: 0rem;
  }

  .details-case-template-container {
    padding: 0px;
    overflow-y: scroll;
  }

  .details-case-template-container::-webkit-scrollbar {
    display: none;
  }

  .details-case-wrapper {
    padding: 0px;
    height: 100%;
    width: 100%;
    display: flex;
  }

  .details-case-table {
    margin-left: 0;
  }
}

@media screen and (max-width: 570px) {
  .details-case-hero {
    width: 300px;
    height: 120%;
  }

  .fullscreen-btn-on {
    position: sticky;
    top: 10;
    right: 20;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .details-case-content-spacer {
    height: 0px;
  }

  .details-case-td {
    padding-left: 5px;
  }

  .details-case-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
  }

  .hero-displayed {
    height: 580px;
    transition: max-height 0.15s ease-out;
  }
}
