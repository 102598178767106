.engine-user-card {
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 8px 0 8px 30px;
  gap: 10px;
}

.value {
  margin-left: 5px;
  font-weight: 400;
}

.card-header-engine-users {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px !important;
  border-radius: 0 !important;
}

.card-header-engine-users h2 {
  margin: 0.5rem 0;
  color: #fff;
}

.engine-user-card .p-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.engine-user-card h3 {
  margin: auto 0 !important;
  color: black;
}

.engine-user-content-wrapper {
  display: flex;
  width: 100%;
  height: auto !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}

.selected {
  box-shadow: 2px 2px 10px #22384d !important;
}

.engine-user-card__statistics-wrapper {
  display: flex;
}

.engine-user-edit-section {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: -48px 21px 0px auto;
}

.data {
  line-height: 25px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 276px;
  margin: 0 !important;
  font-weight: 330 !important;
  font-size: 0.8em !important;
}

.your-account-badge {
  font-size: 0.8em;
  background-color: #3e678e;
  padding: 2px 5px;
  border-radius: 10px;
  width: 100px;
}

.your-account-badge-seleted {
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  padding: 2px 5px;
  border-radius: 10px;
  width: 100px;
  border: #f04 solid 1px;
}

.editingBtn {
  fill: #3e678e !important;
  margin: 0 !important;
  width: 45px !important;
  height: 45px !important;
  background-color: #3e678e !important;
}

.card-container {
  display: flex;
  align-items: center !important;
  gap: 10px;
  border-radius: 5px !important;
  margin-right: 0 !important;
  text-align: left !important;
  width: 100% !important;
  padding: 0.3vw 0.3vw 0.3vw 1.2vw !important;
  min-height: 40px !important;
  height: auto !important;
  background-color: #22384dc1 !important;
}

.card-container2 {
  background-color: rgba(90, 110, 114, 0.139) !important;
  border-radius: 5px !important;
  margin-right: 0 !important;
  height: auto !important;
  text-align: left !important;
  padding: 5px !important;
  margin: 3px 5px 0px 5px !important;
  border: rgba(255, 255, 255, 0.141) solid 1px !important;
}

.card-container-seleted {
  display: flex;
  align-items: center !important;
  gap: 10px;
  border-radius: 5px !important;
  margin-right: 0 !important;
  text-align: left !important;
  width: 100% !important;
  padding: 5px 5px 5px 15px !important;
  min-height: 40px !important;
  height: auto !important;
  border: #f04 solid 1px !important;
  /* background-color: rgba(150, 158, 158, 0.512) !important; */
  opacity: 0.8 !important;
}

.card-container-seleted2 {
  width: calc(100% - 10px) !important;
  border-radius: 8px !important;
  height: auto !important;
  text-align: left !important;
  margin: 5px 5px 0px 5px !important;
  border: rgba(255, 0, 68, 0.2) solid 2px !important;
}

.header-title-seleted {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin: 0 !important;
  margin: 5px 0 !important;
  margin-left: 0px !important;
  font-size: 0.9em !important;
  color: white !important;
  font-weight: 400 !important;
}

.card-container:hover {
  opacity: 0.9;
  border: #f04 solid 1px !important;
}

.card-container-seleted:hover {
  background-color: rgba(58, 99, 140, 0.313) !important;
}

.card-container2:hover {
  opacity: 0.9;
  border: #f04 solid 1px !important;
  background-color: transparent !important;
}

.card-container-seleted2:hover {
  border: #f04 solid 2px !important;
  background-color: rgba(58, 99, 140, 0.313) !important;
}

.header-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-weight: 700 !important;
  font-size: 1em !important;
  margin: 0 !important;
  margin-left: 5px !important;
  color: rgba(255, 255, 255, 0.894) !important;
}

.header-title2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin: 5px 0 !important;
  margin-left: 5px !important;
  color: rgba(255, 255, 255, 0.894) !important;
  font-weight: 400 !important;
  margin-left: 10px !important;
  font-size: 0.9em !important;
}

.state-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 3px;
  padding: 0 0 0 5px;
}

.caseInBatc-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 0px 10px 0px;
  gap: 10px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5);
}

.badge-caseId {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(245, 229, 220, 0.867);
  margin-top: 0px !important;
  font-weight: 700 !important;
  margin-left: 3px !important;
  font-size: 0.9em !important;
}

.badge-state {
  background-color: rgba(19, 47, 210, 0.615);
  border-radius: 8px;
  padding: 2px 10px;
  margin-left: 3px;
  text-align: center;
  font-size: 0.75em;
}

.badge-proccesed {
  background-color: rgba(19, 48, 210, 0.203);
  border-radius: 8px;
  padding: 2px 5px;
  font-size: 0.75em;
  margin-left: 3px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .card-container {
    border-radius: 5px !important;
    margin-right: 0 !important;
    text-align: left !important;
    border: rgba(255, 255, 255, 0.141) solid 1px !important;
    padding: 5px;
    margin: 0px !important;
    background-color: white;
  }
  .state-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 3px;
  }
  .engine-user-card {
    min-width: 230px;
    width: 230px;
    max-width: 320px;
    min-height: 150px;
    max-height: 300px;
    margin: 0.5rem;
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 2px 2px 7px #0401008a !important;
  }

  .data {
    max-width: 230px;
  }

  .owner-info-wrapper span {
    font-size: 0.8rem;
  }

  .user-opts {
    justify-content: space-between;
  }

  .customSeparator {
    display: none;
  }

  .data {
    line-height: 17px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 8px 0 8px 5%;
    gap: 10px;
  }
}

@media screen and (max-width: 570px) {
  .header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .data {
    max-width: 190px;
  }
  .badge-state {
    font-size: 0.7em;
  }

  .badge-proccesed {
    font-size: 0.7em;
  }
}
