.counter-display-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  background-color: #22384D;
  height: auto;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  box-shadow: 2px 2px 7px #0129398a;
}

.counter-display-tag {
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  color: white;
}

.counter-display-value {
  font-size: 30px;
  line-height: 0px;
  color: #f6b41a;
  font-weight: 500;
}


@media screen and (max-width: 570px) {
  .counter-display-wrapper {
    justify-content: space-between;
    padding: .1rem;
    max-width: 100%;
  }

  .counter-display-value {
    font-size: 23px;
    line-height: 0px;
  }

  .counter-display-tag {
    font-size: 1rem;
    text-align: center;
    margin-bottom: .7rem;
  }
}