.notes-wrapper {
  background: #f2f2f2;
  box-shadow: 0px 0px 8px rgba(69, 69, 69, 0.15);
  margin-top: -0px;
  width: 50vw;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0 30px;
}

.notes-title {
  font-size: 1.4375em !important;
  line-height: 35px;
  display: flex;
  width: 100%;
  max-width: 400px;
  align-items: center;
  text-align: center !important;
  color: #6f7a88;
  margin: 0;
  padding: 20px 30px 10px 0px;
}

.notes-subtitle {
  font-size: 1em !important;
  line-height: 30px;
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: flex-start;
  text-align: center !important;
  color: #2b2c2db6;
  margin: 0;
  padding: 0px 30px 0px 30px;
}

.note-detail {
  padding: 7px;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  margin-bottom: 7px;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  color: #6f7a88 !important;
  border: #0c0c0c 2px solid !important;
}

.note-textarea {
  padding: 7px 9px;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  color: #6f7a88 !important;
  max-height: 30vh;
  min-height: 200px;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 7px;
  border: #0c0c0c 2px solid !important;
}

.note-textarea:hover {
  background-color: rgba(186, 182, 182, 0.353);
}

.flex-label {
  display: flex;
}

.bold {
  color: #0c0c0c;
  font-size: 800;
}

.input-label {
  font-weight: 900;
  color: #131313e4;
  line-height: 40px;
  display: block;
  width: auto;
  margin: 0;
  margin-bottom: -50px !important;
}

.input-label-mini {
  font-size: 0.8em;
  font-weight: 500;
  color: #131313e4;
  line-height: 14px;
  display: block;
  width: auto;
  margin: 0px 0;
  padding-right: 20px;
}

.required-input {
  color: #4188d2;
  font-size: 1.1em;
  cursor: default;
  margin: 10px 0;
}
.error-txt {
  display: flex;
  justify-content: flex-start;
  color: #a02003;
  font-size: 0.9em;
  line-height: 24px;
  gap: 8.33px;
  margin-top: -8px;
  margin-bottom: -10px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.note-wrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 10px;
}

.note-date {
  font-size: 0.7em;
  color: #3e678ea6;
  font-weight: 600;
  padding: 0 5px;
}

.yesno-txt2 {
  font-size: 1em !important;
  line-height: 35px;
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  color: #6f7a88;
  margin: 0;
  padding: 40px 30px 20px 30px;
}

.flex-actions-notes {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0px 20px 20px;
}

.mb-primary {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: white !important;
  background-color: #3e678e !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
}

.mb-secondary {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: #6f7a88 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
  border: 1px solid #6f7a88 !important;
}

.mb-secondary {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: #6f7a88 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
  border: 1px solid #6f7a88 !important;
}

.mb-secondary:hover {
  color: white !important;
}

.mb-cancel {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: #be1616 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
  border: 1px solid #be1616 !important;
}

.mb-cancel:hover {
  color: white !important;
}

.loader {
  border: 2px solid rgba(62, 103, 142, 1); /* Light grey */
  border-top: 2px solid #859fcfc7; /* Blue */
  border-radius: 47%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 900px) {
  .yesno-txt {
    max-width: 330px;
  }

  .notes-wrapper {
    width: 90vw;
    max-width: 100%;
  }

  .yesno-txt2 {
    font-size: 1em !important;
    line-height: 35px;
    display: flex;
    max-width: 85vw;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    color: #6f7a88;
    margin: 0;
    padding: 40px 30px 20px 30px;
  }

  .mb-cancel:hover {
    background-color: transparent !important;
    color: #be1616 !important;
  }
}
