.app-form-section {
  padding: 60px 20px 20px 20px !important;
}

.app-formGroup {
  width: 100%;
  justify-content: left;
  align-items: left;
}

.app-form-inputText {
  width: 85%;
  margin-left: auto !important;
}

.app-form-button {
  padding: 20px !important;
  margin-left: auto !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

label {
  font-weight: 500;
}

.parrafo {
  white-space: pre !important;
}

@media screen and (max-width: 900px) {
  .app-form-section {
    padding: 40px 20px 20px 20px !important;
  }
}
