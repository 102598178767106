.dropArea {
  display: flex !important;
  height: auto !important;
  flex-direction: column !important;
  border: 2px dashed #cccccc !important;
  border-radius: 4px;
  padding: 20px !important;
  text-align: center !important;
  cursor: pointer;
  max-width: 1180px;
  margin: 20px 0 10px 0 !important;
  background-color: rgba(208, 222, 234, 0.702) !important;
}

.cases-wrp2 {
  /* background-color: rgb(58, 56, 56); */
  background-color: transparent;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden !important ;
}

.cases-wrp2-ext {
  /* background-color: rgb(58, 56, 56); */
  background-color: transparent;
  max-width: 1280px;
  margin: 0 auto;
  transition: width 0.7s ease-in-out;
}

.cases-wrp {
  /* background-color: rgb(58, 56, 56); */
  background-color: #023347;
}

.dropAreaFull {
  display: flex !important;
  height: auto !important;
  flex-direction: column !important;
  /* border: 2px solid #cccccc !important; */
  border-radius: 4px;
  text-align: center !important;
  cursor: pointer;
  max-width: 1180px;
  margin: 20px 0 10px 0 !important;
  padding: 20px;
  background-color: rgba(168, 176, 182, 0.165) !important;
}

.dropArea:hover {
  border: 2px dashed #f04 !important;
  background-color: rgba(208, 222, 234, 0.702) !important;
  padding: 20px !important;
}

.link-white {
  color: white !important;
  text-decoration: underline !important;
}

.link-btn {
  background-color: transparent !important;
  text-decoration: underline !important;
  color: black !important;
  padding: 0 !important;
  margin-bottom: -4px !important;
  margin-left: 20px !important;
  min-width: 80px !important;
}

.link-btn:hover {
  color: #f04 !important;
  text-decoration: underline !important;
  background-color: transparent !important;
}

.cases-wrp2 {
  /* background-color: rgb(58, 56, 56); */
  background-color: transparent;
  max-width: 1280px;
  width: calc(100vw - 260px);
  margin: 1vw auto;
  padding-top: 1vw;
}

.cases-wrp2-ext {
  /* background-color: rgb(58, 56, 56); */
  background-color: transparent;
  max-width: 1280px;
  width: calc(100vw - 40px);
  margin: 5vw auto 2vw auto;
}

@media screen and (max-width: 900px) {
  .dropArea {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 10px 25px 30px 25px;
    text-align: center;
    cursor: pointer;
    max-width: 80vw;
  }
  .cases-wrp {
    padding-top: 0vw;
  }
  .cases-wrp2 {
    /* background-color: rgb(58, 56, 56); */
    background-color: transparent;
    max-width: 1280px;
    width: calc(100vw - 109px);
    margin: 10px 20px;
  }
}

@media screen and (max-width: 570px) {
  .cases-wrp2 {
    /* background-color: rgb(58, 56, 56); */
    background-color: transparent;
    max-width: 1280px;
    width: calc(100vw - 104px);
    margin: 4vw;
  }
}
