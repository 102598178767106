.history-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 1.8rem 0;
  max-width: 1280px;
}

.HI-group-container {
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.HI-group-container::-webkit-scrollbar {
  display: none;
}

.history-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 93vh;
  text-align: center;
  border-radius: 10px;
  border-top-left-radius: 0px;
  max-width: 1280px !important;
  width: calc(100%);
  overflow: hidden;
  margin: 0 auto;
}

.history-container2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 93vh;
  text-align: center;
  border-radius: 10px;
  border-top-left-radius: 0px;
  max-width: 1280px !important;
  width: calc(100vw - 40px);
  overflow: hidden;
  margin: 0 auto;
  margin-top: 10px;
}

#filter-teams {
  display: flex;
  justify-content: flex-start;
  background-color: #25627f !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 0px;
  max-width: 1180px;
  width: calc(100vw - 680px);
  margin: 0px auto;
}

.history-A {
  display: flex;
  padding: 20px !important;
  gap: 10px;
  align-items: center;
  height: 50px;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.history-A::-webkit-scrollbar {
  display: none;
}

.history-B {
  height: calc(100% - 160px);
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

.history-A-people {
  width: auto;
  margin: 2px 0px 0 15px;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 0.4em !important;
}

.history-A-filters {
  display: flex;
  align-items: center;
  gap: 10px;
}

.history-A-search {
  z-index: 1000;
  margin-left: -10px !important;
}

.p-multiselect-header {
  display: none !important;
}

.p-multiselect-panel {
  border-radius: 8px !important;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0 !important;
}

.p-multiselect-item {
  padding: 5px 15px 5px 10px !important;
  width: 100% !important;
  max-width: 100% !important;
  display: flex !important;
  color: rgba(26, 26, 26, 0.83) !important;
  font-size: 0.8em !important;
}

.p-multiselect-item .p-highlight {
  color: white !important;
  background-color: transparent !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: #a3a5a6 !important;
  color: white !important;
}

.history-ppl-button {
  border-radius: 50% !important;
  font-size: 1.5em !important;
  height: 32px !important;
  width: 32px !important;
  margin-left: -10px !important;
  margin-bottom: 4px !important;
  margin-right: 5px !important;
  color: white !important;
  display: flex;
  justify-content: center;
  background-color: #335475 !important;
  box-shadow: #22384d 2px 2px 7px !important;
  border: #3e678e 3px solid !important;
}

.history-ppl-button:hover {
  z-index: 2000 !important;
  font-size: 14px !important;
  padding: 18px 16px !important;
}

.history-avatar {
  border-radius: 50% !important;
  font-size: 0.4em !important;
  height: 38px !important;
  width: 43px !important;
  margin-bottom: 0px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.avatar-align {
  height: 30px !important;
  width: 35px !important;
  border-radius: 50% !important;
  font-size: 12px !important;
}

.avatar-align:hover {
  background-color: #22384d !important;
  cursor: default;
}

.avatar-align-empty {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 35px !important;
  border-radius: 50% !important;
  font-size: 12px !important;
  color: black !important;
}

.avatar-align-empty:hover {
  background-color: transparent !important;
  cursor: default;
}

.chip {
  height: 18px;
  width: auto;
  margin: 0;
  padding: 0px 10px;
  text-align: left;
  font-size: 15px;
}

.history-A-users {
  background-color: transparent !important;
  height: 30px !important;
  margin-left: -5px !important;
}

.statusChip {
  display: flex;
  justify-content: center;
}

.groupChip {
  display: flex;
  height: 30px;
  padding: 6px 10px 5px 10px !important;
  border-radius: 7px;
  justify-content: center !important;
  color: white;
  font-size: 1em;
  margin: 0 20px;
  max-width: 200px;
  text-align: center !important;
}

.wrapper-conditions {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100px;
}

.conditions {
  margin: 0px !important;
  font-size: 1em !important;
}

.wrapper-groups {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 7px 15px;
  width: auto;
  gap: 10px;
  border-radius: 10px;
}

.wrapper-groups2 {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  margin: auto !important;
  align-items: center;
  padding: 3px 12px;
  border-radius: 10px;
  display: flex !important;
  margin-left: 0px !important;
  color: black !important;
  font-size: 0.9em !important;
}

.groups {
  margin: 0px !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: auto;
}

.green {
  background-color: #52b67aa4;
}

.yellow {
  background-color: #cc8a19ba;
}

.red {
  background-color: #cb3234bd;
}

.editButton {
  display: flex;
  justify-content: center;
}

.editionButton:hover {
  background-color: #f04 !important;
}

.editionButton {
  margin: 0 !important;
  height: 30px !important;
  width: 30px !important;
}

.conditionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.list-empty-wrapper {
  height: calc(100vh - 224px);
  max-width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* @media screen and (max-width: 1300px) {
  .wrapper-groups2 {
    width: 230px !important;
  }
} */

@media screen and (max-width: 1200px) {
  #filter-teams {
    display: flex;
    padding: 0px;
    margin: 0px 0 0px 0px;
    padding-left: 10px;
  }
  .history-B {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  #filter-teams {
    padding-left: 5px;
    width: calc(100vw - 107px);
  }
  .history-B {
    height: calc(100% - 150px);
    width: calc(100vw - 109px);
    margin: 0 !important;
  }
  .wrapper-groups2 {
    width: 180px !important;
    padding: 7px 0 !important;
  }

  .history-wrapper {
    padding: 0;
  }

  .history-container {
    height: 93.5vh;
    box-shadow: none;
    margin: 0 !important;
  }

  .list-empty-wrapper {
    height: calc(100vh - 135px);
    margin: 0 15px 0 0;
    border-top-left-radius: 0px;
  }

  .table-wrapper-history {
    padding: 0 0 0 2rem;
  }

  .history-dropdown {
    width: 70% !important;
  }
}

@media screen and (max-width: 570px) {
  .list-empty-wrapper {
    height: calc(80vh - 135px);
    width: calc(100vw - 100px);
    margin: 10px 15px 0 15px;
    border-top-left-radius: 30px;
  }
  #filter-teams {
    padding-left: 10px !important;
    width: calc(100vw - 107px);
  }
}
