#editAppCard {
  width: 100%;
}

.edit-app-formgroup {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1180px;
  gap: 0px;
}

.webhooks-info {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.webhooks-constainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.edit-app-formgroup label {
  font-size: 18px;
  font-weight: 500;
}

.edit-app-formgroup button {
  width: 50px;
  height: 50px;
  justify-content: center;
  background-color: #f8f9fa;
  border: none;
  margin-left: 0.5rem;
}

.edit-app-formgroup img {
  width: 50px;
}

.edit-app-formgroup img {
  width: 50px;
}

.app-calendar {
  margin-left: 8%;
}

.app-calendar div {
  margin-top: 0.3rem;
}

.edit-app-formgroup:last-child {
  margin-top: 1.5rem;
}

.edit-app-formgroup:last-child label {
  margin-bottom: 0.5rem;
}

.app-analytics {
  margin-left: 6%;
}

.edit-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  width: 94%;
  height: auto !important;
  padding-bottom: 20px;
}

.edit-selectedWorkflow {
  display: flex;
  padding-top: 8px;
  color: #3e678e !important;
}

.title-txt {
  margin-top: 20px !important;
  font-size: 1.6em;
}

@media screen and (max-width: 900px) {
  .webhooks-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .edit-selectedWorkflow {
    width: auto !important;
    text-align: center !important;
  }
  .webhooks-constainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 570px) {
  .edit-wrapper {
    width: 100%;
    margin: 0;
    padding-left: 0px;
  }
}
