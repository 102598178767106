.HI-wrapper {
  height: 100%;
  width: 100%;
  margin: 1.9rem 0rem 0rem 0rem;
}
.human-wrapper {
  height: 100%;
  width: 100%;
  margin: 2vw auto 0rem auto;
  max-width: 1280px !important;
  width: 100%;
}

.loader-wrapper {
  height: 100vh;
  width: 100%;
}

.HI-container {
  display: flex;
  justify-content: space-between;
  height: 93vh;
  width: 100%;
}

.mail-img {
  width: 120px;
}

.HI-listing {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.HI-details {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.HI-resfesh-container {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}
.myticket-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 0;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.myticket-wrapper::-webkit-scrollbar {
  display: none;
}

.refresh-img {
  height: 100%;
  width: auto;
}

.refresh-bt2 {
  z-index: 1000000 !important;
  height: 60px !important;
  width: 50px !important;
  background-color: #22384d !important;
  border-radius: 50px !important;
  margin-right: 0 !important;
}

.add-ticket-btn {
  min-height: 24px !important;
  max-height: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100px;
  background-color: #5d7e9e !important;
  box-shadow: 2px 2px 7px #0129398a !important;
  border-radius: 50% !important;
  margin-right: 5px !important;
  margin-left: 0px !important;
}

.refresh-basket {
  margin-top: 0px !important;
  margin-right: 0px !important;
  display: flex !important;
  justify-content: center;
  background-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
  background-color: #3e678e !important;
  gap: 10px !important;
  min-width: 28px !important;
  /* margin-left: auto !important; */
  /* box-shadow: 2px 2px 3px #0129398a !important; */
}

.refresh-basket2 {
  margin-top: 0px !important;
  margin-right: 0px !important;
  display: flex !important;
  justify-content: center;
  background-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
  background-color: #3e678e !important;
  gap: 10px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.add-ticket-btn2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100px;
  width: 100% !important;
  background-color: #5d7e9e !important;
  box-shadow: 2px 2px 7px #0129398a !important;
  border-radius: 50% !important;
  margin-right: 5px !important;
  margin-left: 10px !important;
}

.HI-listing-search {
  height: 2.5rem;
}

.HI-listing-container {
  padding: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 150px;
}

.HI-listing-container::-webkit-scrollbar {
  display: none;
}

.p-highlight {
  box-shadow: none !important;
}

.p-input {
  width: 100% !important;
}

.p-inputtext {
  border-radius: 8px !important;
}

.p-input-icon-left > .p-inputtext {
  width: 100% !important;
  padding: 0 2rem 0 2.4rem !important;
  box-shadow: none !important;
}

.search-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(181, 181, 181) !important;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  min-width: 1280px !important;
  max-width: 1280px !important;
  padding: 0px 10px 0 15px;
  margin: 0px 0 0px 0;
  height: 60px;
  gap: 10px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.search-wrapper::-webkit-scrollbar {
  display: none;
}

.my-ticket-clear {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-left: 10px !important;
  height: 38px !important;
}

.group-filter-wrapper {
  margin: 0 !important;
}

.detail-wrapper {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.detail-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  height: auto;
}

.detail-img {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: auto !important;
}

.refresh-basket {
  color: white !important;
}

.detail-message {
  color: #22384d;
  font-size: 1.3em;
  text-align: center;
  margin: 20px 0 0px 0 !important;
  max-width: 400px !important;
}

.refresh-txt {
  background-color: transparent !important;
  color: #22384d !important;
  text-decoration: underline #22384d;
  font-size: 1.2em !important;
  height: 40px !important;
}

.refresh-txt:hover {
  background-color: transparent !important;
  color: #f04 !important;
  text-decoration: underline #f04;
}

.buttonFilter {
  height: 40px !important;
  margin-right: 10px !important;
  margin-left: 0px !important;
  padding-left: 10px !important;
  padding-right: 30px !important;
  margin-bottom: 0px !important;
  border-radius: 8px !important;
  min-width: 220px !important;
  color: #3e678e !important;
  border: #3e678e 2px solid !important;
}

.buttonFilter::placeholder {
  color: #3e678e !important;
  opacity: 1; /* Firefox */
}

.buttonFilter2::placeholder {
  color: #3e678e !important;
  opacity: 1; /* Firefox */
}

.buttonFilter2 {
  height: 40px !important;
  margin-right: 10px !important;
  margin-left: 0px !important;
  padding-left: 33px !important;
  padding-right: 30px !important;
  padding-bottom: 4px !important;
  margin-bottom: 0px !important;
  border-radius: 8px !important;
  color: #3e678e !important;
  width: 87% !important;
  max-width: 255px !important;
  min-width: 255px !important;
  border: #3e678e 2px solid !important;
}

.wrapper-conditions {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 0px;
  width: 100px;
  gap: 10px;
  border-radius: 10px;
}

.empty-listing {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: #6f7a88;
  gap: 10px;
  flex-direction: column;
  margin-left: -35px;
}

.empty-basket {
  height: 7vmin;
  font-size: 1.5em;
}

.detail-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-left: 10px;
}

@media screen and (max-width: 1520px) {
  .HI-details {
    overflow: hidden;
  }
  .myticket-wrapper {
    max-width: auto;
    width: calc(100vw - 281px);
  }
  .search-wrapper {
    max-width: calc(100vw - 300px);
    gap: 1vw;
  }
}

@media screen and (max-width: 900px) {
  .HI-listing-container {
    padding: 0;
    height: 60vh;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 150px;
  }
  .HI-listing {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-top: 15px;
  }
  .human-wrapper {
    height: 100%;
    width: 100%;
  }
  .myticket-wrapper {
    margin: 0px 0 20px 0;
    width: calc(100vw - 120px);
  }
  .buttonFilter {
    margin-right: 10px !important;
    margin-bottom: 00px !important;
    max-width: 70vw !important;
    min-width: 50vw !important;
    margin-left: 0 !important;
  }

  .HI-resfesh-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .refresh-bt2 {
    z-index: 1000000 !important;
    height: 60px !important;
    width: 50px !important;
    background-color: #22384d !important;
    border-radius: 50px !important;
    margin-right: 0 !important;
  }

  .add-ticket-btn {
    width: 75px !important;
  }

  .empty-listing {
    margin-left: 0;
  }
  .search-wrapper {
    height: 60px;
    min-width: 100% !important;
  }

  .detail-img {
    display: flex;
    flex-direction: column;
  }

  .HI-wrapper {
    margin: 1rem 0 0rem auto;
    box-shadow: none;
  }

  .HI-container {
    flex-direction: column;
    background-color: transparent;
    height: calc(100vh - 55px);
  }

  .HI-listing-search {
    font-size: 14px !important;
    width: 100% !important;
  }

  .HI-details {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .HI-details::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 570px) {
  .HI-listing {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 0vw;
  }
  .buttonFilter2 {
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    width: auto !important;
    min-width: 45vw !important;
    max-width: 45vw !important;
    padding-right: 2vw !important;
  }
  .detail-message {
    color: #22384d;
    font-size: 1.3em;
    text-align: center;
    max-width: 60vw !important;
  }
  .myticket-wrapper {
    max-width: 780px;
    margin: 20px auto;
    width: calc(100vw - 106px);
  }
  .HI-wrapper {
    height: 100%;
    margin: 0.5rem auto 0rem auto;
    width: auto;
  }

  .HI-container {
    height: 100vh;
  }

  .HI-details {
    width: 100%;
  }
}
