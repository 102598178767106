.p-menubar {
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
    height: 90px !important;
    padding: .1rem 0 !important;
    margin-top: -10px !important;
}

.menu-wrapper {
    display: flex;
    justify-content: space-between !important;
    padding: 2.8rem 1rem 0 1rem;
    padding-top: 10px;
}

.menu-logo {
    margin-top: -20px;
    padding: 20px 5px 1px 15px;
    width: 78px;
    height: 65px;
    transition: width 2s, height 2s;
}

.close-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    font-size: 22px;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 50px;
}

.close-menu:hover {
    background-color: #f04;
}

.menu-title {
    font-weight: normal;
}

.logo-image {
    width: 25%;
    margin: 0 2rem;
}

.p-menubar-end {
    display: flex;
    justify-content: flex-end;
}


.title-wrapper {
    display: flex;
    padding: 0 0;
    color: white;
    align-items: center;
    font-weight: normal;
}

.workspace {
    font-size: 2rem;
    width: 300px;
    margin-bottom: 10px;
    font-weight: normal;
}

.workspace-section {
    font-size: 1.5rem;
}

@media screen and (max-width: 900px) {

    .menu-logo {
        margin-top: -5px;
        padding: 5px 1px 10px 10px;
        width: 70px;
        height: 60px;
        transition: width 2s, height 2s;
    }

    .p-menubar {
        height: 70px;
    }

    .menu-wrapper {
        padding: 2.8rem 1rem 0 1rem;
        margin-top: -10px;
        margin-bottom: 45px;
    }

    .card {
        width: 100% !important;
    }

    .workspace {
        font-size: 1.5rem;
        width: 100%;
    }

    .workspace-section {
        font-size: 1rem;
    }

    .close-menu:hover {
        background-color: transparent;
    }

}