.statsbar-wrapper {
  margin: 0rem 0rem 0rem 0rem;
  display: flex;
  gap: 15px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: calc(100vw - 310px);
  max-width: 1180px;
  height: 120px;
}

.statsbar-wrapper::-webkit-scrollbar {
  display: none;
}

.subtilte-hr {
  font-size: 1em;
  color: #22384d;
  margin-top: 0 !important;
}

.subtilte-hr2 {
  font-size: 1em;
  color: #3e678e;
  margin-top: 0 !important;
}

.line-hr {
  background-color: #22384d;
  margin-top: -8px !important;
  margin-bottom: 5px !important;
}

.line-hr2 {
  background-color: #3e678e;
  margin-top: -8px !important;
  margin-bottom: 5px !important;
}

.card-stats {
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  background-color: #22384d;
  gap: 20px;
  justify-content: center;
  border-radius: 8px;
}

.card-header {
  font-size: 0.7em;
  color: white;
  text-align: center;
}

.card-value {
  font-size: 1.4em;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  color: white;
}

.status-cls {
  background-color: #3e678e;
  border-color: #f04;
}

.states-cls {
  /* border-bottom: 1px solid #f04; */
}

.line-end {
  border-left: #3e678e7c 2px solid;
  height: 55%;
  margin-top: 25px !important;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 900px) {
  .statsbar-wrapper {
    margin: 0rem 0rem 0.5rem 0.1rem;
    width: calc(100vw - 108px);
  }

  .card-stats {
    border: 1px solid black;
  }
  .status-cls {
    background-color: #3e678e;
    border-color: #f04;
  }
  .line-end {
    border-left: #3e678e7c 2px solid;
    height: 50%;
    margin-top: 29px;
    margin-left: -5px;
  }
}
