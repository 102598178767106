.caseType-container {
  background-color: rgba(185, 185, 185, 0.207) !important;
  padding: 0.5vw 1vw;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  gap: 1vw;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.caseType-container::-webkit-scrollbar {
  display: none;
}

.card-batch {
  border-radius: 8px;
  padding: 10px;
  max-width: 150px;
  min-width: 150px;
  background-color: rgb(34, 56, 77);
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 15px;
  border: 1px solid #3e678e18;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.card-batch:hover {
  color: #f04 !important;
  border: 1px solid #f04;
}

.card-batch2 {
  border-radius: 8px;
  padding: 5px !important;
  background-color: rgb(34, 56, 77);
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 260px;
  gap: 10px;
  border: 1px solid #3e678e18;
  box-shadow: 2px 2px 3px #79a4b58a !important;
  color: white !important;
}

.casetype-name {
  color: white;
  font-size: 0.8em;
}

.casetype-value {
  color: white;
  font-size: 1em;
}

.card-hr {
  color: black;
  border: 1px solid rgba(16, 31, 35, 0.312);
}

@media screen and (max-width: 900px) {
  .card-batch {
    padding: 5px 0;
  }
  .card-batch:hover {
    color: #f04 !important;
    border: 1px solid #f04;
  }
  .card-batch2 {
    border-radius: 8px;
    padding: 5px 10px;
    background-color: rgba(28, 82, 97, 0.565);
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 15px;
    border: 1px solid #3e678e18;
    box-shadow: 2px 2px 3px #0129398a !important;
    color: white !important;
  }
}

@media screen and (max-width: 570px) {
}
