.stats-card {
  background: #fff;
  flex-basis: 15%;
  padding: 1rem 0.5rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  margin: 1rem 0;
}
.bigcard {
  flex-basis: 30%;
}
.column-card {
  flex-direction: column;
  margin: 01rem 0.5rem;
}
.horizontal-section {
  flex-basis: 50%;
}
