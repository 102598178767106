.page-not-authorized-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-left: 3px solid #f04 !important; */
}

.page-not-authorized-container {
  min-width: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  overflow: hidden;
}

.page-not-authorized-label {
  font-size: 20px;
  margin: 20px 0;
  text-align: center;
  font-weight: 330;
}

.page-not-authorized-img {
  height: 200px;
  width: 100%;
}

.page-not-authorized-btn {
  width: 100px !important;
  min-height: 40px !important;
  background-color: #3e678e !important;
}

@media screen and (max-width: 900px) {
  .page-not-authorized-img {
    height: 100px;
    width: 100%;
  }
}
