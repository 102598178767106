.recovery-loader {
  height: 90vh;
}

.white-txt {
  color: white;
  font-size: 1.2em;
  padding: 20px 10px 30px 10px;
  max-width: 400px;
}

.expired-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 25vh;
}

.back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 17px 10px !important;
  cursor: pointer;
  color: white !important;
  background-color: #22384d !important;
  box-shadow: none !important;
  margin: 0 !important;
  height: 40px !important;
  width: 100% !important;
  max-width: 120px;
}

@media screen and (max-width: 900px) {
  .white-txt {
    color: white;
    font-size: 1.2em;
    padding: 30px 10px;
    max-width: 70vw;
  }
  .expired-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 15vh;
  }
}
