.dashboard-charts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chartjs-render-monitor {
  height: 100% !important;
  width: 100% !important;
  min-width: 400px !important;
  font-size: 0.6em !important;
}

.chart-img {
  width: 120px;
  height: 100px;
}

.table-chart-wrapper {
  height: calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-message {
  font-size: 15px;
  color: black;
  text-align: center;
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 900px) {
  .dashboard-charts-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .chartjs-render-monitor {
    height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .chart-img {
    width: 150px;
    height: 150px;
  }

  .chart-message {
    font-size: 20px;
    color: black;
    text-align: center;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 570px) {
  .chartjs-render-monitor {
    height: 65% !important;
  }
}
