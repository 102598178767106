.section-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titles {
  padding: 5px 0;
  font-size: 1.2em;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #185f7394 !important;
  margin: 0;
  width: 100%;
  display: flex;
}

.titles2 {
  padding: 5px 0;
  font-size: 1.2em;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #185f7394 !important;
  margin: 10px;
  width: 100%;
  display: flex;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.overAll-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.case-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.case-wrapper {
  height: 81%;
}

.overAll-details {
  width: 90%;
  margin: 0 auto;
  border-radius: 10px !important;
}

.overAll-details-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0px;
  padding: 10px 0 10px 0;
  background-color: #3e678e25;
  color: white;
}

.overAll-details-row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
  padding: 5px 15px;
}

.overAll-details-row:nth-child(even) {
  background-color: #5455560c !important;
}

.overAll-details-row2 {
  display: flex;
  width: 100%;
  max-height: 33.3%;
  height: 20%;
  gap: 10px;
}

.overAll-details-row2:nth-child(even) {
  background-color: #22384d24 !important;
}

.overAll-details-row3 {
  background-color: #22384d;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-height: 33.3%;
  height: 20%;
  gap: 10px;
}

.case-sub-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case-sub-title {
  font-size: 12px;
  font-weight: 300;
  color: white;
  margin: 0;
}

.case-sub-value {
  font-size: 20px;
  color: white;
}

.overAll-details-title {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
}

.overAll-details-title3 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  text-align: left !important;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-self: center;
}

.overAll-details-title2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #22384d;
}

.overAll-details-types {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overAll-details-types::-webkit-scrollbar {
  display: none;
}

.overAll-details-scroller {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: auto;
  width: 100%;
  gap: 5px;
  margin: 0;
  padding: 0px 10px 0px 10px;
}

.lines-hr {
  width: 100%;
  margin-top: 0px !important;
}

.overAll-details-chip {
  border-radius: 5px;
  font-size: 0.7em;
  padding: 5px;
  color: white;
  margin: 0;
  cursor: pointer;
  text-align: center;
}

.no-cursor {
  cursor: default !important;
}

.overAll-details-avg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 300;
  width: auto;
}

.case-description {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

@media screen and (max-width: 1350px) {
  .titles {
    font-size: 1.2em;
    font-weight: 500;
  }

  .overAll-details-title2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border-top-left-radius: 0px;
    overflow: hidden;
    max-height: 45vw;
  }
  .overAll-details-scroller {
    max-height: 100px;
  }

  .overAll-details-chip {
    border-radius: 5px;
    font-size: 0.6em;
    padding: 1.3px;
    color: white;
    margin: 0;
    cursor: pointer;
    text-align: center;
  }
  .overAll-details {
    width: 100%;
    margin: 0 auto;
    border-radius: 10px !important;
    margin-bottom: 20px;
  }
  .overAll-container {
    max-width: 100%;
  }
  .section-container {
    display: flex;
    flex-direction: column;
  }

  .overAll-details-title3 {
    width: 100%;
    display: flex;
    margin: 0;
    min-width: 130px;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
  }

  .overAll-details-title2 {
    min-width: 250px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: left;
    font-size: 16px;
  }

  .case-description {
    font-size: 12px;
  }

  .overAll-details-avg {
    font-size: 1.3em;
  }
  .titles {
    font-size: 1.4em;
    font-weight: 600;
    margin-top: 0px;
  }
  .lines-hr {
    width: 100%;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 570px) {
  .section-container {
    display: flex;
    flex-direction: column;
  }

  .overAll-details-title2 {
    min-width: 130px;
    max-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: left;
    font-size: 14px;
  }

  .case-description {
    font-size: 10px;
    padding: 0;
  }

  .overAll-details-avg {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    width: calc(100% - 200px);
  }
}
