.HI-wrapper2 {
  height: 100%;
  margin-top: 30px;
  margin-left: 0;
}

.settings-tickets-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: 1180px;
  width: calc(100vw - 280px);
  margin: 0 auto;
  border: 3px #659f195a solid;
  border-top-width: 0px;
  border-left-width: 0px;
  border-top-right-radius: 40px;
  background-color: #22384d22 !important;
}

.HI-listing-container2 {
  height: 72vh;
}

.header-tickets-bg2 {
  background-color: #22384d !important;
  color: white !important;
}

.settings-listing {
  height: 100%;
  width: 350px;
  overflow: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 0px 10px #2b0139f5 !important;
}

.settings-details {
  width: 300px;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.HI-listing-search {
  height: 70px;
}

.p-highlight {
  box-shadow: none !important;
}

.manager-cls {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.p-input {
  width: 100% !important;
}
.p-inputtext {
  height: 40px !important;
  font-size: 1.1em !important;
  border-radius: 8px !important;
}

.p-dropdown {
  height: 40px !important;
  font-size: 1em !important;
  border-radius: 8px !important;
}

.p-input-icon-left > i:first-of-type {
  left: 0.7rem !important;
  top: 1.25rem !important;
}

.search-wrapper-2 {
  display: flex;
  align-items: center;
  background-color: #22384d;
  min-height: 54px;
  width: 100%;
  border-top-right-radius: 30px;
  padding: 0 10px 0 2vw;
}

.listing {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.listing::-webkit-scrollbar {
  display: none;
}

.HI-details-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  overflow: hidden;
  margin-top: 15vh;
}

.settings-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 245, 245);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
}

.detail-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px;
  height: auto !important;
  border-radius: 20px;
}

.detail-img {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 20vh;
}

.wrapper-conditions {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  gap: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 1100px) {
  .settings-tickets-container {
    flex-direction: row;
    height: calc(100vh - 120px);
    background-color: transparent;
    margin-top: 30px;
  }
  .settings-listing {
    height: 100%;
    width: 270px;
    overflow: hidden;
  }

  .settings-details {
    height: 100%;
    width: calc(100% - 270px);
    overflow: hidden;
  }

  .HI-details-settings {
    overflow: hidden;
  }
  .HI-wrapper2 {
    margin: 30px 0rem 0rem 0rem;
    box-shadow: none;
    height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .search-wrapper-2 {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    border-top-right-radius: 28px;
  }
  .p-dropdown {
    height: 35px !important;
    font-size: 1em !important;
    border-radius: 8px !important;
  }
  .p-inputtext {
    height: 35px !important;
    font-size: 1em !important;
    border-radius: 8px !important;
  }
  .detail-img {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 250px;
  }

  .HI-details-settings {
    margin: 0;
  }

  .detail-container {
    padding: 0 5%;
    gap: 10px;
  }

  .HI-wrapper2 {
    margin: 1rem 0rem 0rem 0rem;
    box-shadow: none;
    height: 100%;
  }

  .settings-tickets-container {
    flex-direction: row;
    height: calc(100vh - 100px);
    width: calc(100vw - 123px);
    background-color: transparent;
    margin-right: 0;
    margin-left: 0;
    margin-top: 30px;
  }

  .settings-listing {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 00px;
  }

  .HI-listing-search {
    font-size: 14px !important;
    width: 100% !important;
  }

  .settings-details {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
  }

  .settings-details::-webkit-scrollbar {
    display: none;
  }

  .settings-wrapper {
    height: 44vh;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 570px) {
  .HI-wrapper2 {
    overflow: hidden;
  }

  .detail-container {
    padding: 0;
    flex-direction: column;
  }

  .settings-tickets-container {
    height: calc(100vh - 170px);
    width: calc(100vw - 108px);
    margin-top: 14px;
  }

  .settings-listing {
    margin-top: 0;
    padding-top: 0;
    color: #e6e6e6;
    box-shadow: 5px 0px 10px rgb(131, 65, 79) !important;
  }

  .settings-details {
    height: 95%;
    width: 100%;
  }

  .settings-wrapper {
    height: 42vh;
    margin: auto;
  }
}
