.caseType-container {
  overflow: hidden;
  overflow-x: scroll;
  width: 100%;
  display: flex;
  gap: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.caseType-container::-webkit-scrollbar {
  display: none;
}

.cases-container {
  height: 85vh;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 32px;
  padding-bottom: 120px;
  max-width: 1280px;
  margin: 0 auto;
}

.cases-container::-webkit-scrollbar {
  display: none;
}

.caseType2-container {
  width: 50%;
  padding: 10px;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.caseType2-container::-webkit-scrollbar {
  display: none;
}

.paginator-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin: 10px 2px 0 0;
  color: white !important;
}

.paginator-bg {
  display: flex;
  align-items: center;
  background-color: #22384d !important;
  border-radius: 8px !important;
  box-shadow: 2px 2px 3px #0129398a !important;
  min-width: 190px !important;
  gap: 5px;
  padding: 3px;
}

.secuencial-cls {
  display: flex !important;
  justify-content: center !important;
  font-size: 0.7em !important;
  width: 50px;
}

.paginator-flex {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
}

.paginator-total-txt {
  color: #3e678e !important;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  margin-right: 2px;
  background-color: #3e678e14;
  padding: 2px 5px;
  border-radius: 8px;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.paginator-total {
  background-color: #3e678e;
  color: white !important;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #2c4f6f;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  height: 10%;
  border: 1px solid #3e678e18;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.paginator-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 20px !important;
  margin: 0 !important;
  height: 25px !important;
  color: white !important;
  background-color: transparent !important;
  padding: 0 !important;
  padding-bottom: 2px !important;
}

.paginator-page {
  width: 70px !important;
  background-color: #2c4f6f !important;
  color: white !important;
  border: none !important;
  pad: 10px 30px !important;
  margin: 0 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-align: center !important;
}

.paginator-page:hover {
  background-color: #f04 !important;
  color: white !important;
  border: 1px solid #f04;
}

.card-batch:hover {
  color: #f04 !important;
  border: 1px solid #f04;
}

.card-batch3 {
  border-radius: 8px;
  padding: 10px 20px;
  background-color: rgba(76, 150, 170, 0.312);
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 15px;
  height: 10%;
  width: 30%;
  border: 1px solid #3e678e18;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.casetype-name {
  color: black;
  font-size: 0.8em;
}

.casetype-value {
  color: black;
  font-size: 1em;
}

.adj-right {
  margin-left: -15px;
}

.adj-left {
  margin-right: -15px;
}

@media screen and (max-width: 900px) {
  .cases-container {
    height: 75vh;
  }
  .card-batch {
    padding: 5px 0;
  }
  .card-batch:hover {
    color: #f04 !important;
    border: 1px solid #f04;
  }
  .caseType-container {
    padding: 5px 5px 7px 5px;
  }

  .paginator-wrapper {
    min-width: 190px !important;
  }
}

@media screen and (max-width: 570px) {
  .paginator-wrapper {
    margin-top: 20px;
  }
}
