.recovery-section {
  padding: 40px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recovery-img {
  margin: 30px auto 30px auto;
  width: 300px;
  background-color: rgba(128, 128, 128, 0.215);
  padding: 20px;
  border-radius: 30px;
}

.recovery-cancel {
  background-color: transparent !important;
  color: #22384d !important;
  padding: 20px !important;
}

.recovery-cancel:hover {
  color: white !important;
}

.recovery-primary {
  padding: 20px !important;
  color: white !important;
}

.keep-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.name-txt {
  font-family: 'Elbony', sans-serif;
  color: rgb(73, 70, 70) !important;
}

.disclosure-txt {
  text-align: center;
  font-size: 1.2em !important;
  color: white !important;
  max-width: 450px !important;
  margin: 0px 10px 40px 10px;
}

@media screen and (max-width: 900px) {
  .recovery-img {
    margin: 30px auto 30px auto;
    width: 40vw;
    background-color: rgba(128, 128, 128, 0.215);
    padding: 20px;
    border-radius: 30px;
  }
}

@media screen and (max-width: 570px) {
  .recovery-img {
    margin: 30px auto 30px auto;
    width: 50vw;
    background-color: rgba(128, 128, 128, 0.215);
    padding: 20px;
    border-radius: 30px;
  }
  .recovery-section {
    padding: 30px 20px;
  }
}
