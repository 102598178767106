.pwd-h2 {
  color: white;
  text-align: center;
  margin-top: -20px;
}

.pwd-h3 {
  color: rgba(255, 255, 255, 0.769);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1em;
  font-weight: 500;
  max-width: 200px;
}

.pwd-h4 {
  color: rgba(255, 255, 255, 0.769);
  margin-top: 30px;
  margin-bottom: 1rem;
  font-size: 1.3em;
  font-weight: 400;
  text-align: center;
  max-width: 300px;
}

.pwd-section {
  padding: 0px 30px;
}

.recoveryInputText {
  padding: 7px 10px 7px 35px !important;
  border: none !important;
  background-color: white !important;
  border-radius: 8px !important;
  height: 42px !important;
  width: 320px !important;
  margin-bottom: 30px;
}

.sended-img {
  width: 200px;
}

.success-recovery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rvy-primary {
  display: flex !important;
  justify-content: center !important;
  height: 35px !important;
  padding: 0 20px !important;
  background-color: #3e678e !important;
  margin: 0 !important;
  color: white !important;
}

.rvy-cancel {
  height: 40px !important;
  padding: 0 30px !important;
}

.error-bg {
  width: auto;
  border-radius: 4px;
  padding-left: 10px;
}

.error-white {
  display: flex;
  justify-content: flex-end;
  color: rgb(192, 135, 135);
  font-size: 0.9em;
  line-height: 24px;
  gap: 8.33px;
  margin-top: -8px;
  margin-bottom: -10px;
  margin-right: 5px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media screen and (max-width: 900px) {
  .keep-row {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .rvy-cancel {
    margin: 0 !important;
    min-width: 100% !important;
  }
}

@media screen and (max-width: 570px) {
  .pwd-section {
    padding: 0;
  }
  .recoveryInputText {
    border: none !important;
    background-color: white !important;
    border-radius: 8px !important;
    height: 42px !important;
    margin-bottom: 30px;
  }
}
