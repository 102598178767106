.dropArea {
  display: flex !important;
  height: auto !important;
  flex-direction: column !important;
  border: 2px dashed #cccccc !important;
  border-radius: 4px;
  padding: 20px !important;
  text-align: center !important;
  cursor: pointer;
  max-width: 1180px;
  margin: 20px 0 10px 0 !important;
  background-color: rgba(208, 222, 234, 0.702) !important;
}

.dropAreaFull {
  display: flex !important;
  height: auto !important;
  flex-direction: column !important;
  /* border: 2px solid #cccccc !important; */
  border-radius: 4px;
  text-align: center !important;
  cursor: pointer;
  max-width: 1180px;
  margin: 20px 0 10px 0 !important;
  padding: 20px;
  background-color: rgba(168, 176, 182, 0.165) !important;
}

.dropArea:hover {
  border: 2px dashed #f04 !important;
  background-color: rgba(208, 222, 234, 0.702) !important;
  padding: 20px !important;
}

.link-btn {
  background-color: transparent !important;
  text-decoration: underline !important;
  color: black !important;
  padding: 0 !important;
  margin-bottom: -4px !important;
  margin-left: 20px !important;
  min-width: 80px !important;
}

.link-btn:hover {
  color: #f04 !important;
  text-decoration: underline !important;
  background-color: transparent !important;
}

@media screen and (max-width: 900px) {
  .dropArea {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 10px 25px 30px 25px;
    text-align: center;
    cursor: pointer;
    max-width: 80vw;
  }
}

@media screen and (max-width: 570px) {
}
