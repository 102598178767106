#header {
  height: 100% !important;
  display: flex;
  justify-content: center;
}

#header .pro-sidebar {
  height: 100vh;
  color: #fff;
  max-height: 1180px !important;
}

.pro-sidebar {
  height: 100vh;
  color: #fff;
  max-height: 1180px !important;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
  align-self: center;
  transition: width 0.8s;
}

.bagde-red {
  background-color: red !important;
}

.bagde-yellow {
  background-color: #ffff00 !important;
}

.bagde-red:hover {
  background-color: #22384d !important;
}

.bagde-counter {
  font-size: 0.9em;
  border-radius: 10%;
  padding: 2px 5px !important;
  background-color: #3e678e;
  width: auto;
  height: 20px;
  color: white;
  z-index: 100000;
}

.bagde-flat-counter {
  font-size: 0.7em !important;
  border-radius: 10%;
  padding: 3px 7px !important;
  background-color: #3e678ecf;
  width: auto;
  height: 19px;
  color: white;
  z-index: 100000;
}

.bagde-activity {
  font-size: 0.9em;
  border-radius: 50%;
  padding: 0px !important;
  background-color: rgb(1, 1000, 3);
  min-width: 13px;
  min-height: 13px;
  color: white;
  margin-left: -14px;
  margin-top: -0px;
  z-index: 2;
}

.bagde-activity2 {
  font-size: 0.9em;
  border-radius: 50%;
  padding: 0px !important;
  background-color: rgb(1, 1000, 3);
  min-width: 5px;
  min-height: 5px;
  color: white;
  margin-top: -2px;
}

.navbar-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header .pro-sidebar.collapsed {
  width: 190px;
}

.username-txt {
  display: flex;
  font-size: 1em !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #f04;
  padding: 4px 1vw;
  text-align: center !important;
  border-radius: 8px;
  margin-left: 0px;
}

.workspace-txt {
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  background-color: rgba(84, 84, 84, 0.182);
  padding: 6px 10px 6px 40px;
  box-shadow: 2px 2px 7px #0129398a !important;
  width: auto !important;
  text-align: center !important;
  border-radius: 8px;
  font-weight: 500;
}

.workspace-txt2 {
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  background-color: rgba(84, 84, 84, 0.182);
  padding: 6px 3px;
  box-shadow: 2px 2px 7px #0129398a !important;
  width: 86% !important;
  text-align: center !important;
  border-radius: 8px;
  font-weight: 500;
}

.username-txt:hover {
  box-shadow: 2px 2px 7px #0129398a !important;
}

.header-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.userBtn {
  height: 40px !important;
  width: auto !important;
  min-width: 40px !important;
  border: none !important;
  display: flex !important;
  gap: 10px !important;
  flex-direction: row !important;
  justify-content: center !important;
  border-radius: 0px !important;
  padding: 0 !important;
  align-items: center !important;
  margin: 0 !important;
  background-color: transparent !important;
}

.userBtn:hover {
  background-color: transparent !important;
  opacity: 0.9 !important;
}

.workspaceBtn {
  height: 60px !important;
  width: 100% !important;
  border: none !important;
  display: flex !important;
  gap: 10px !important;
  flex-direction: row !important;
  justify-content: center !important;
  border-radius: 0px !important;
  background: rgb(34, 56, 77) !important;
  background: linear-gradient(
    295deg,
    rgba(34, 56, 77, 1) 10%,
    rgba(62, 103, 142, 1) 130%
  ) !important;
}

.workspaceBtn:hover {
  background-color: transparent !important;
  opacity: 0.9 !important;
}

.userBtn2 {
  height: auto !important;
  width: 100% !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 100% !important;
  background-color: transparent !important;
  margin: 0 !important;
}

.userBtn2:hover {
  background-color: #3e678ee1 !important;
}

.nav-user {
  height: 35px;
  padding: 2px;
  margin-left: 0px !important;
  margin-right: -40px !important;
  border-radius: 10px;
  z-index: 100;
}

.nav-setting {
  height: 35px;
  padding: 2px;
  margin-left: 0px !important;
  margin-right: -40px !important;
  border-radius: 10px;
  z-index: 10000;
}

.nav-setting:hover {
  background-color: #f04 !important;
}

.nav-user:hover {
  background-color: #f04 !important;
}

.nav-userMobile {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  height: 19px;
  box-shadow: 2px 2px 7px #d4d8da33 !important;
  margin: 0px !important;
}

#header .pro-sidebar.expanded {
  width: 0px !important;
}

#header .pro-sidebar-inner {
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  background: rgb(34, 56, 77);
  background: linear-gradient(-75deg, rgba(34, 56, 77, 1) 60%, rgba(62, 103, 142, 1) 130%);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout::-webkit-scrollbar {
  display: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  width: 100%;
  padding: 0 15%;
  font-weight: 500;
  min-width: 220px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  font-weight: 500;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  margin: 0;
  border-radius: 3px;
}

.meno-txt {
  background-color: transparent;
}

#header .active {
  background-color: #f04 !important;
}

.pro-menu-item:hover {
  background-color: #f04 !important;
}

#header .logo {
  padding: 20px;
}

#header .pro-icon-wrapper {
  background-color: transparent;
  height: 30px;
}

.menu-opt {
  background-color: transparent !important;
  color: #fff !important;
  margin-left: 15px !important;
  margin-top: 1px !important;
  font-size: 1.1em !important;
  display: flex;
  text-align: center !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.footer-menu-opts {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0 0 0;
  padding: 0 15%;
}

.pro-inner-item {
  display: flex !important;
  gap: 0px;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  padding: 0.2rem 0.5rem !important;
  color: #ffffff !important;
  border-top: rgba(139, 137, 137, 0.453) 0.5px solid;
  border-bottom: rgba(139, 137, 137, 0.453) 0.5px solid;
}

.pro-sidebar .pro-menu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.nav-icon {
  width: 30px;
  height: 30px;
}

.out-txt {
  margin: 0 !important;
  margin-left: 15px !important;
  margin-top: 2px !important;
  font-size: 1.1em !important;
}

.nav-icon-out {
  width: 20px;
  height: 30px;
}

.nav-logo {
  width: 30px;
  transition: width 2s, height 2s;
}

@media screen and (max-width: 900px) {
  .username-txt {
    text-align: center !important;
    margin-left: 0px !important;
    margin-bottom: 0 !important;
    font-size: 1.3em !important;
  }

  .workspace-txt {
    display: flex;
    justify-content: center;
    padding: 3px 0px;
    width: 80% !important;
    text-align: center !important;
    border-radius: 8px;
    font-size: 0.7em;
    margin-top: 3px;
    margin-bottom: -3px;
  }
  .bagde-activity {
    position: absolute;
    top: 0;
    font-size: 0.9em;
    border-radius: 50%;
    padding: 2px 5px !important;
    width: 5px !important;
    height: 5px !important;
    color: white;
    margin-left: 29px;
    margin-top: 28px;
  }

  .bagde-counter {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    padding: 2px 5px !important;
    background-color: #3e678e;
    width: auto;
    height: auto;
    color: white;
    margin-top: 27px;
    margin-right: 10px;
  }

  .bagde-flat-counter {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 10%;
    padding: 2px 4px !important;
    width: auto;
    height: auto;
    color: white;
    margin-top: 27px;
    margin-right: 15px;
  }

  .userBtn2 {
    display: flex !important;
    align-items: center !important;
    height: auto !important;
    min-height: 55px !important;
    flex-direction: column !important;
    width: 60px !important;
    font-size: 0.5em !important;
    background-color: transparent !important;
  }

  .userBtn2:first-child {
    display: flex !important;
    align-items: center !important;
    height: auto !important;
    min-height: 55px !important;
    flex-direction: column !important;
    width: 55px !important;
    font-size: 0.5em !important;
    background-color: transparent !important;
  }

  .workspaceBtn2 {
    height: auto !important;
    width: 100% !important;
    min-height: 55px !important;
    flex-direction: column !important;
    font-size: 0.6em !important;
    margin: 0 !important;
    background-color: transparent !important;
  }

  .workspaceBtn2:hover {
    background-color: transparent !important;
    opacity: 0.9 !important;
  }

  .nav-user {
    height: 25px;
    padding: 2px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 5px !important;
    box-shadow: 2px 2px 7px #d4d8da33 !important;
    border-radius: 10px;
    z-index: 1000000;
  }

  .menu-opt {
    margin-left: 0 !important;
  }
  .out-txt {
    margin: 0 !important;
    margin-left: 0 !important;
  }
  .nav-logo {
    transition: width 2s, height 2s;
  }
  #header .pro-sidebar.collapsed {
    width: 65px;
  }
  .pro-inner-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem !important;
    gap: 0 !important;
  }
  html {
    overflow: hidden;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 570px) {
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    min-height: 5px !important;
    height: 5px !important;
    margin: 12px 0 16px 0 !important;
  }
  .pro-inner-item {
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    gap: 0 !important;
  }
}
