.report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 0 0 0 0;
  margin-top: 0px;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 20px 0 0px 0px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.history-btn {
  background-color: #3e678e !important;
  margin-top: 5px;
  margin-right: 3rem !important;
  padding: 10px 30px !important;
  color: white !important;
  width: 150px !important;
}

.report-container::-webkit-scrollbar {
  display: none;
}

.report-wrapper {
  display: inline-block;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0px auto 15px auto;
  max-width: 1180px;
  padding-bottom: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.report-wrapper::-webkit-scrollbar {
  display: none;
}

.inRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.card {
  padding: 10px 3rem;
}

.card:first-child {
  padding-right: 0px;
  padding-top: 0px;
}

.overview {
  /* background-color: beige; */
  flex: 1 0 50%;
}

.totalRisk {
  /* background-color: red; */
  flex: 1 0 65%;
  height: 210px;
}

.alert {
  /* background-color: blue; */
  flex: 1 0 50%;
  height: 210px;
  padding-top: 0;
  padding-left: 20px;
}

.overall {
  flex: 1 0 100%;
  height: 400px;
}

.impact {
  flex: 2 0 100%;
  height: 400px;
}

.report-subtitle {
  font-size: 1em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.759);
  margin: 13px 20px;
}

.body-report {
  border: 1px solid #22384d12;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(214, 232, 247, 0.298);
}

@media screen and (max-width: 900px) {
  .report-header {
    padding: 0px;
    height: 50px;
  }

  .history-btn {
    margin-right: 20px !important;
    padding: 10px 10px !important;
  }
  .card {
    padding: 15px 20px 0px 20px;
  }
  .card:first-child {
    padding-right: 20px;
    padding-top: 0px;
  }
  .card:last-child {
    padding: 15px 20px 20px 20px;
  }
  .report-container {
    padding: 0;
  }
  .inRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .report-wrapper {
    display: inline-block;
    flex-direction: column;
    height: 100vh;
  }

  .overview {
    flex: 1 0 20%;
    /* background-color: red; */
    height: 100px;
  }

  .totalRisk {
    flex: 1 0 20%;
    max-height: 300px;
  }

  .alert {
    height: 200px;
  }

  .overall {
    flex: 0;
    height: 500px;
  }
  .impact {
    flex: 0;
    height: 500px;
  }
}
