.flex-left-container2 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 20px);
  margin-left: 20px;
  gap: 1vw;
}

.flex-left-container3 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  margin-right: 20px;
  gap: 1vw;
  background-color: #333e5417;
}

.empty-cls {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.badge-page {
  background-color: rgba(146, 172, 228, 0.315);
  border-radius: 50%;
  margin-right: 1vw;
  padding: 3px 5px;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.flex-pager2 {
  margin-top: 1vw;
  display: flex;
  align-items: center;
  text-align: flex-start;
  width: 100%;
  gap: 1vw;
}

.active-btn {
  background-color: red !important;
  margin-top: 300px !important;
}

.sebasEnd {
  background-color: rgba(255, 0, 0, 0.02) !important;
  margin-right: 20px !important;
}

.page-wrap {
  width: 100%;
  height: 30px !important;
  color: #333e54d2 !important;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 10px !important;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  margin-bottom: 1vw !important;
}
.start-badge {
  background-color: rgb(155, 183, 223);
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 0.5em;
  color: white;
  margin-left: 1vw;
}
.end-badge {
  background-color: rgb(86, 90, 97);
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 0.5em;
  color: white;
  margin-left: 1vw;
}

.active-badge {
  background-color: #f04;
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 0.5em;
  color: white;
  margin-left: 1vw;
}

.borderStart {
  border-bottom: rgb(192, 210, 235) 1px solid !important;
}
.borderEnd {
  border-bottom: rgb(86, 90, 97) 1px solid !important;
}
.page-wrap:hover {
  background-color: rgba(126, 131, 131, 0.129) !important;
}

.pager-title {
  margin: 0 0 0px 0;
  height: 20px;
}

.pager-options {
  margin: 0 !important;
  background-color: transparent !important;
  width: 22px !important;
}

.pager-add-btn {
  background-color: transparent !important;
  text-decoration: underline !important;
  color: black !important;
}

.pager-options:hover {
  margin: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
}
