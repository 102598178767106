.batchChip {
  display: flex;
  width: auto;
  justify-content: center;
  border-radius: 8px;
  padding: 4px 10px;
  color: white !important;
  box-shadow: 2px 2px 3px #0129398a !important;
  margin-right: 10px;
}

.ownerChip {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 20px;
  color: #335475;
}

.exe-btn {
  background: linear-gradient(
    125deg,
    rgba(34, 56, 77, 1) 60%,
    rgba(62, 103, 142, 1) 130%
  ) !important;
  padding: 1px 5px !important;
  box-shadow: 2px 2px 3px #0129398a !important;
}

.exe-btn:hover {
  background: #f04 !important;
}

.stop-btn {
  background-color: #d6870f !important;
  padding: 6px 20px !important;
  box-shadow: 2px 2px 3px #0129398a !important;
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 570px) {
}
