.support-wrapper {
  padding: 20px;
}

.submit-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
