.thunder-form-wrapper {
  margin-top: 50px;
  padding: 0px 20px 20px 20px;
  max-width: 500px;
}

.thunder-step {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding-bottom: 30px;
}

.thunder-subHeader {
  line-height: 1.4em;
}

.control-arrow {
  color: black;
}

@media screen and (max-width: 900px) {
  .thunder-form-wrapper {
    max-width: 500px;
    padding-left: 10px;
  }
  .thunder-step {
    height: 130px;
  }
  .thunder-label {
    max-width: 55vw;
  }
}
