.trash-can {
  height: 10vh;
  margin: 60px 0 40px 0;
}

.primary-btn {
  background: rgba(62, 103, 142, 1) !important;
  padding: 20px !important;
  min-width: 100px;
  font-size: 1em !important;
}

.secondary-btn {
  border: 1px solid rgba(62, 103, 142, 1) !important;
  padding: 20px !important;
  min-width: 100px;
  font-size: 1em !important;
  background-color: transparent !important;
}

.primary-btn:hover {
  background: #f04 !important;
}

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 330px;
}

.conf-txt {
  text-align: center;
  width: 70%;
  font-size: 1.2em;
  color: white !important;
}

.action-flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 10px;
  width: 100%;
  margin: 20px 0px 20px 0;
}

.conf-close {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
  padding: 20px !important;
  border: 1px solid white !important;
}

.conf-close:hover {
  color: whitesmoke !important;
}
