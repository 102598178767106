.tab-section-wrapper {
  margin: 2rem 0 0 1rem;
}

.user-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.p-tabview .p-tabview-panels {
  padding: 0px !important;
  margin: 1rem 0rem 0rem 0rem;
  background: transparent !important;
  border: none;
}

.p-tabview-panels {
  padding: 0px !important;
  margin-top: -30px !important;
  margin-left: 0px !important;
}

.top-adjust {
  margin-top: 33px !important;
}

.action-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 20px 0 0 0;
}
.roles-len {
  font-size: 0.6em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background-color: #f04;
  color: white !important;
  border-radius: 8px;
  height: 20px;
  margin-right: 10px;
}

.roles-txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 40px !important;
  color: white;
  padding: 0vw 1.4vw !important;
  font-size: 1.1em;
  background: rgb(181, 181, 181) !important;
  margin: 0 auto !important;
  font-weight: 600;
}

.roles-txt2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  max-height: 40px !important;
  color: white;
  padding: 10px 0px 9px 0px;
  font-size: 1.2em;
  margin: 0 auto !important;
  margin-right: 0 !important;
  font-weight: 600;
}

.p-tabview-nav {
  margin: 1rem 0 30px 3rem !important;
  background-color: transparent !important;
}

.p-tabview-nav-link {
  background-color: #335e96 !important;
  padding: 5px 5px !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
}

.p-tabview-nav-link:hover {
  background-color: #1f4371 !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
}

.p-tabview-selected {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.section-container-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clean-btn {
  width: 30px !important;
  min-width: 30px !important;
  margin-left: -48px !important;
  background-color: transparent !important;
  margin-right: 30px !important;
  color: #023347 !important;
}
.clean-btn:hover {
  background-color: #02334729 !important;
}

.clean-btn2 {
  display: flex;
  gap: 10px;
  margin-top: 20px !important;
  background-color: #cc12124c !important;
  color: rgba(255, 255, 255, 0.626) !important;
  text-decoration: underline;
  margin-right: 0 !important;
  padding: 10px !important;
}
.clean-btn2:hover {
  background-color: #cc121260 !important;
}

.section-container-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 70px;
  margin: 2rem;
}

.section-header {
  padding: 1rem;
  width: 34%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-info-container {
  width: 30%;
  min-width: 180px;
  max-width: 350px;
  margin: 1rem 0.7rem;
  padding: 1rem 1rem;
  background-color: #fff;
  border-style: solid;
  border-color: rgba(195, 195, 231, 0.433);
}

.account-info-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.account-info-button-wrapper {
  margin-right: 10%;
}

.action-button {
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 45px !important;
}

.engine-options {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.engine-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: calc(100% - 60px);
  width: calc(100% -200px);
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 1150px;
}

.engine-list-wrapper::-webkit-scrollbar {
  display: none;
}

.button {
  width: 190px;
  border: 1px solid #fff;
  box-shadow: 2px 2px 5px #023347;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
}

.button:hover {
  box-shadow: 2px 2px 7px #0129398a;
}

.contT {
  padding-bottom: 0px;
}

.engine-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 30px auto;
  height: 100vh;
  width: calc(100%);
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  overflow: hidden;
  background-color: #f4f4f4 !important;
}

.left-engine {
  height: 100%;
  overflow: hidden;
  width: 38%;
  min-width: 315px;
  max-width: 200px;
  background-color: rgba(151, 172, 200, 0.368) !important;
}

.left-search {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 58px !important;
  padding: 28px 10px 30px 10px;
  background: rgb(181, 181, 181) !important;
}

.left-scroller {
  padding: 0;
  height: calc(100% - 0);
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 300px;
}

.left-scroller::-webkit-scrollbar {
  display: none;
}

.left-scroller2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #22384d22 !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 300px;
}

.left-scroller2::-webkit-scrollbar {
  display: none;
}

.right-engine {
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.right-engine::-webkit-scrollbar {
  display: none;
}

.right-engine2 {
  height: 100%;
  width: 75%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  background-color: rgba(204, 225, 253, 0.368);
  scrollbar-width: none;
}

.right-engine2::-webkit-scrollbar {
  display: none;
}

.full-engine {
  height: 100%;
  width: 100%;
  border: 3px rgba(208, 222, 234) solid;
  background-color: rgba(208, 222, 234, 0.702) !important;
}

.details-message {
  text-align: center;
  margin: 20px 0 0px 0;
  font-size: 1.2em;
  max-width: 300px;
  color: #22384dc1 !important;
}

.details-batch {
  text-align: center;
  margin: 10px 0;
  font-size: 1em;
  font-weight: 800;
  max-width: 400px;
  color: #335e96 !important;
}

.actioner {
  height: 32px !important;
  width: 120px !important;
  margin: 0px !important;
  margin-left: 20px !important;
  padding: 0 20px !important;
}

.icon {
  font-size: 2rem;
  color: white;
}

.section-subscribers-wrapper {
  display: flex;
  align-items: center;
}

.section-subscribers-list-container {
  width: 85%;
}

.section-subscribers-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 15%;
}

.section-subscribers-buttons-wrapper button {
  margin: 1rem 0;
  width: 100%;
}

.table-header {
  justify-content: flex-start;
}

.statics-number {
  font-size: 30px;
  line-height: 0px;
  color: #f6b41a;
  font-weight: 500;
}

.statics-tag {
  font-size: 1rem;
  line-height: 0px;
}

.loading-container {
  margin-top: 30px;
  height: 85vh;
}

.section-container-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  overflow-y: scroll;
  min-height: 30vh;
  max-height: 90vh;
}

.section-container-wrapper {
  -ms-overflow-style: none;

  scrollbar-width: none;
}

.section-container-wrapper::-webkit-scrollbar {
  display: none;
}

.modal-title {
  margin-top: -10px !important;
  margin: 0;
  text-align: left;
}

.btn-add {
  margin: 0 !important;
  min-height: 40px;
  min-width: 220px;
  align-self: right !important;
}

@media screen and (max-width: 1400px) {
  .engine-section {
    display: flex;
    justify-content: flex-start;
    margin: 32px 0;
    width: calc(100%);
    border-top-left-radius: 0 !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 1200px) {
  .engine-section {
    margin: 32px 0 0 0 !important;
  }
  .right-engine {
    height: 100%;
    width: 100%;
    padding-bottom: 0px;
  }
  .right-engine2 {
    height: 100%;
    width: 64%;
    padding-bottom: 0px;
  }
  .user-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .left-search {
    display: flex;
    align-items: center;
    width: 100%;
    /* padding: 10px 10px 10px 10px; */
    padding: 32px 10px 35px 10px;
  }
  .left-engine {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .engine-section {
    padding: 0rem;
    box-shadow: none;
  }
}

@media screen and (max-width: 900px) {
  .p-tabview-nav-link {
    padding: 10px 3vw 10px 3vw !important;
    width: auto !important;
    display: flex !important;
    justify-content: center !important;
  }
  .right-engine {
    display: none;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  .right-engine2 {
    display: none;
    height: 87%;
    overflow: hidden;
    width: 100%;
  }
  .clean-btn {
    width: 30px !important;
    margin-left: -45px !important;
    background-color: transparent !important;
    margin-right: 30px !important;
    color: #023347 !important;
  }
  .p-tabview-panels {
    background-color: #023347 !important;
    width: 100%;
    max-width: 1180px !important;
    padding: 0px !important;
    margin-top: -30px !important;
    margin-left: 0px !important;
  }
  .top-adjust {
    margin-top: 31px !important;
  }

  .loading-container {
    margin-top: 18px;
    height: 85.6vh;
  }

  .p-tabview-nav {
    margin: 1.5rem 0 30px 3rem !important;
  }

  .tab-section-wrapper {
    margin: 0 2rem;
  }

  .section-container-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    gap: 1rem;
  }

  .counter-display-wrapper {
    max-width: 80%;
  }

  .spanner {
    display: none;
  }

  .engine-list-wrapper {
    padding-top: 0;
    height: calc(100% - 70px);
    justify-content: flex-start;
    align-items: center;
    gap: 5vw;
  }

  .left-scroller {
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  .left-scroller2 {
    padding: 0;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  .action-button {
    padding-right: 20px;
    height: 70px !important;
  }

  .account-info-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .account-info-items-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .account-info-button-wrapper {
    margin: 1rem 0 0 0;
  }

  .statics-number {
    font-size: 25px;
  }
}

@media screen and (max-width: 570px) {
  /* .left-engine {
    height: 77%;
  } */
  .left-scroller {
    padding: 0;
    height: 90%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  .engine-section {
    padding: 0rem;
    box-shadow: none;
  }
  .p-tabview-nav {
    margin: 1.5rem 0px 0 1.7rem !important;
  }
  .right-engine {
    height: 77%;
  }
}
