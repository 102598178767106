/* src/SignaturePad.css */

.signature-container {
  padding: 30px 4vw 2vw 4vw;
  text-align: center;
  margin: 0px auto;
  display: inline-block;
  position: relative;
}

.sigCanvas {
  border: 1px solid;
  border-radius: 5px;
  height: 170px;
  width: 100%;
}

.image-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  height: 100%;
}

.image-sign {
  height: 180px;
  min-width: 100%;
  padding: 10px 3vw;
}

.text-intro-sign {
  margin: 10px 0;
  text-align: left;
  color: white;
}

.buttons {
  margin: 30px 0 0px 0;
}

.signature-preview {
  margin-top: 20px;
}

.signature-preview img {
  border: 5px solid #000;
  border-radius: 5px;
  width: 500px;
  height: auto;
}

@media screen and (max-width: 900px) {
  .signature-container {
    padding: 20px 4vw 2vw 4vw;
    text-align: center;
    display: inline-block;
    position: relative;
    max-width: 80vw;
  }
  .sigCanvas {
    border: 1px solid;
    border-radius: 5px;
    height: 170px;
  }
}

@media screen and (max-width: 570px) {
  .sigCanvas {
    border: 1px solid;
    border-radius: 5px;
    height: 170px;
    width: 100%;
  }
}
