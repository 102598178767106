/* Force Outlook to provide a "view in browser" button. */
* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Elbony', sans-serif;
}

html,
body,
#root {
  height: 100vh !important;
  overflow: hidden;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #3e678e;
  margin-top: 0.5;
  margin-bottom: 0.5;
}

body {
  height: 100vh;
  background: #22384d;
  /* background-image: url("./assets/images/hound-line.png");
  background-repeat: no-repeat;
  background-size: cover; */
  margin: 0%;
  font-family: 'Elbony', sans-serif;
}

.content-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  margin-bottom: 10px;
}

.flex-row {
  display: flex;
  align-items: center;
  gap: 0px;
}

.flex-row2 {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  color: white !important;
  margin-bottom: 10px;
  align-items: center;
}

.toast-demo button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

.separator {
  background-color: #fff;
  width: 0.02rem;
  height: 2rem;
}

.horizontal-separator {
  height: 0.02rem;
  width: 100%;
  margin: 0.5rem;
}

.black {
  background-color: #000;
}

*:focus {
  outline: 0 !important;
}

.purple {
  background: #7657a5 !important;
}

.blue {
  background: #31abe8 !important;
}

.aqua {
  background: #4bc0c0 !important;
}

.baby-blue {
  background: #235faf !important;
}

.grey {
  background: rgba(133, 127, 129, 0.5) !important;
}

.light-purple {
  background: rgba(114, 92, 164, 0.5) !important;
}

.light-blue {
  background: #448fff !important;
}

.orange {
  background: #e29208 !important;
}

.blue-gradient {
  background: #22384d !important;
}

.blue-classic {
  background: #2b8ae3 !important;
}

.blue-green {
  background: #61d3d4 !important;
}

.blue-mb {
  background: #275da5 !important;
}

.red-mb {
  background: #a53a27 !important;
}

.light-green {
  background: #4eab33 !important;
}

.color1 {
  background: #143c562e !important;
}

.color2 {
  background: #10ff5c2e !important;
}

.color3 {
  background: #725ca459 !important;
}

.color4 {
  background: #8ac7bb !important;
}

.color5 {
  background: #6bcd844b !important;
}

.color6 {
  background: #b1d3d4 !important;
}

.never-active {
  background: transparent !important;
}

.white-color {
  color: #fff !important;
}

.section {
  margin: 0.5rem 0;
}

.rounded-borders {
  border-radius: 1.25rem !important;
}

.input-required-text {
  color: rgb(238, 201, 201);
}

.input-required {
  border-color: #dc3545 !important;
}

.tab-section {
  padding: 0.5rem 1rem;
}

.section-background {
  background: ff7f00;
}

#triangle-up {
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 1rem solid rgb(9, 255, 0);
}

#triangle-down {
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 1rem solid red;
}

.toast {
  z-index: 1300 !important;
  width: 370px !important;
  border-radius: 20px !important;
}

.modal {
  min-width: 500px;
  max-width: 800px;
}

.toolForm {
  width: auto;
  height: auto;
}

.notesForm {
  width: auto;
  height: auto;
}

.submenu-popup {
  position: absolute !important;
  top: 30px !important;
  right: 30px !important;
  background-color: transparent !important;
}

.displayer-file {
  background-color: blue;
}

.status-1 {
  background-color: #7fcdcd;
}

.status-2 {
  background-color: #6b5b95;
}

.status-3 {
  background-color: #88b04b;
}

.status-4 {
  background-color: #ff6f61;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #22384d;
}

::selection {
  color: white;
  background: #22384d;
}

@media screen and (max-width: 900px) {
  .submenu-popup {
  }
  .displayer-file {
    position: absolute !important;
    top: 20px !important;
    right: 7px !important;
    background-color: transparent !important;
  }

  .toast-demo button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .modal {
    max-width: 90%;
    min-width: 90%;
  }

  .toolForm {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .toast {
    width: 60vw !important;
  }
}
