.p-card {
  background-color: rgba(255, 255, 255, 0.98) !important;
  border-radius: 0px !important;
}

.authentication-route {
  justify-content: center;
  align-items: center;
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  background: rgb(34, 56, 77);
  background: linear-gradient(125deg, rgba(34, 56, 77, 1) 40%, rgba(62, 103, 142, 1) 130%);
  backdrop-filter: blur(3px);
  background-image: url('https://cdn.houndsoftware.net/assetstmp/clouds.png');
  background-position: 40% 20%;
}

.action-section {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 35px;
  width: auto;
}

.version-btn {
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: whitesmoke;
}

@media screen and (max-width: 900px) {
  .authentication-route {
    max-width: 100%;
    background-position: 44% 10%;
  }
  .version-btn {
    position: absolute;
    bottom: 25px;
    right: 35px;
  }
}

@media screen and (max-width: 570px) {
  .authentication-route {
    max-width: 100%;
    background-position: 33% 10%;
  }
}
