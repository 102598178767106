.app-form-section {
  padding: 20px 20px 20px 20px !important;
}

.app-form-section-2-columns {
  padding: 2%;
  columns: 2;
}

.app-formGroup {
  font-size: 16px;
  width: 36vw !important;
  justify-content: left;
  align-items: left;
}

.app-form-inputText {
  width: 85%;
  margin-left: auto !important;
}

.app-form-button {
  padding: 20px !important;
  margin-left: auto !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

label {
  font-weight: 500;
  padding-right: 2%;
}

.custom-data {
  white-space: pre !important;
}
@media screen and (max-width: 900px) {
  .app-form-section {
    padding: 60px 20px 20px 20px !important;
  }
  .app-formGroup {
    font-size: 0.9em;
    width: 60vw !important;
  }
}

@media screen and (max-width: 570px) {
  app-form-section-2-columns {
    columns: 1;
  }
  .app-formGroup {
    font-size: 0.9em;
    width: 75vw !important;
  }
}
