.notify-channel {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  gap: 20px;
  margin: 15px 0;
}

.notify-txt {
  gap: 10px;
}

.advanced-cls3 {
  background-color: transparent !important;
  color: #3e678e !important;
  text-decoration: underline !important ;
  margin: 0 !important;
  padding: 0.5rem 0.1rem !important;
  margin-right: 20px !important;
}

.advanced-cls3:hover {
  color: white !important;
}

.spanner {
  padding-bottom: 100px;
}

.spanner2 {
  height: 170px !important;
  width: 100% !important;
}

.advanced-cls {
  font-size: 0.8em !important;
  width: 150px !important;
  height: 40px !important;
  color: white !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}
.advanced-cls:hover {
  color: white !important;
}

.flex-color {
  display: flex;
  align-items: center;
  width: 100%;
}

.colorAvatar {
  min-height: 20px;
  min-width: 20px;
  margin: 0 15px 0 20px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.625);
}

.text-area-input {
  width: 100% !important;
  height: 35px !important;
  min-height: 35px !important;
  max-height: 100px !important;
  margin-top: 4px !important;
  background-color: rgba(145, 140, 140, 0.308) !important;
  color: white !important;
}

.edit-ticket-wrapper {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}

.operator {
  margin: 0px !important;
  font-size: 14px !important;
}

.edit-container {
  display: flex;
  align-items: center;
  padding-top: 5px !important;
  flex-direction: column;
  height: auto;
  width: 100% !important;
  gap: 0px;
}

.ticket-container2 {
  padding: 20px 30px 30px 30px !important;
  width: 500px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 90vh;
  width: 0 auto;
}

.ticket-container {
  padding: 60px 30px 30px 30px !important;
  max-height: 80vh !important;
  overflow-y: scroll;
}

.input-form {
  padding: 7px 9px;
  border: none;
  font-size: 1em;
  background-color: #fff !important;
  color: #6c757d !important;
  width: 100% !important;
  margin-top: 3px !important;
  margin-bottom: 5px !important;
  height: 37px !important;
  border-radius: 8px !important;
}

.input-form:disabled {
  color: #6c757d !important;
  opacity: 0.6 !important;
}

.blue-input {
  border: none;
  font-size: 1em;
  background-color: #3e678e !important;
  color: #6c757d !important;
  width: 100% !important;
  height: 40px !important;
}

.input-form:hover {
  background-color: #fff !important;
}

.edit-container-left {
  flex-grow: 1;
  min-width: 100% !important;
  max-width: 270px !important;
  gap: 10px !important;
  margin-bottom: 10px;
}

.edit-container-left2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50px;
  width: 100% !important;
  gap: 10px !important;
  margin-bottom: 20px;
}

.edit-container-right {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.edit-ticket-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
  color: white !important;
}

.edit-ticket-footer:disabled {
  color: white !important;
}

.edit-action-btn {
  padding: 20px 20px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.43) !important;
  background-color: #3e678e !important;
}

.edit-action-btn:disabled {
  background-color: rgb(98, 98, 98) !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.top-adjust {
  margin-top: -5px;
}

.advanced-cls3 {
  font-size: 0.9em !important;
}

@media screen and (max-width: 900px) {
  .edit-container-left {
    min-width: 70vw !important;
    max-width: 70vw !important;
  }

  .ticket-container2 {
    padding: 60px 20px 20px 30px !important;
    width: auto;
  }
  .ticket-container {
    padding: 60px 20px 20px 30px !important;
  }
}

@media screen and (max-width: 570px) {
  .advanced-cls3 {
    font-size: 0.6em !important;
    margin-right: 3vw !important;
  }
  .blue-input {
    min-width: auto !important;
  }
  .text-area-input {
    width: 100% !important;
    height: 35px !important;
    min-height: 35px !important;
    max-height: 100px !important;
    margin-top: 4px !important;
    background-color: rgba(145, 140, 140, 0.308) !important;
    color: rgba(0, 0, 0, 0.595) !important;
  }
  .edit-container {
    padding-top: 5px !important;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    max-width: 100%;
    gap: 20px;
  }

  .ticket-container {
    margin-top: 30px;
    padding: 30px 20px 20px 20px !important;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 80vh;
  }

  .ticket-container::-webkit-scrollbar {
    display: none;
  }
}
