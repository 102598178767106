.flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.full-height {
  height: 100%;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.aling-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
