.apps-history-wrapper {
  width: 100%;
  height: calc(100%);
  padding: 3vw;
  max-width: 1180px;
  margin: 0 auto;
}

.instances-title {
  margin: 0 20px 20px 25px;
}
.instaneID-cls {
  font-size: 0.8em;
}

.apps-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.app-overview {
  display: flex;
  flex-direction: center;
  align-items: center;
  margin-bottom: 1rem !important;
  height: 50px;
  gap: 10px;
  background-color: rgba(62, 85, 82, 0.374);
  padding: 2px 0 7px 1px;
  border-radius: 20px;
}

.marginTop {
  margin-top: 10px;
  margin-left: 10px !important;
}

.action-btn {
  height: 20px !important;
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.product-overview-apps {
  gap: 20px;
  margin-top: 7px;
  margin-bottom: 5px;
  margin-left: 0rem;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 75vw;
}

.product-overview-apps::-webkit-scrollbar {
  display: none;
}

.overview-apps-wrapper {
  display: flex;
  width: 100%;
  height: 50px;
}

.status-1 {
  background-color: transparent;
  color: rgb(95, 94, 94) !important;
}

.search-btn {
  margin: 0 0 0 10px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

@media screen and (max-width: 900px) {
  .product-overview-apps {
    margin-left: 0px;
  }
  .app-overview {
    display: flex;
    flex-direction: center;
    align-items: center;
    margin-bottom: 0.8rem !important;
    height: 50px;
    padding: 15px;
    background-color: rgba(62, 85, 82, 0.374);
    padding: 7px 0 7px 2px;
    border-radius: 10px;
  }
  .apps-container {
    margin-top: 0px;
  }
}

@media screen and (max-width: 570px) {
  .apps-history-wrapper {
    width: 100%;
    height: calc(100%);
    /* padding: 10px; */
  }
}
