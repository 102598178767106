.add-batch-section {
  display: flex;
  flex-direction: column;
  padding: 60px 30px 20px 30px !important;
  text-align: left !important;
  min-width: 380px;
  background-color: #414f5c !important;
}

.batch-ct {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-jobs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.trash-batch {
  max-height: 0px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}
.info-batch-file {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white !important;
}

.input-form-textarea {
  min-height: 19vh;
  max-height: 40vh;
  min-width: 400px;
}

.input-form-textarea2 {
  min-height: 100px;
  max-height: 40vh;
  min-width: 400px;
}

.community-img {
  margin-top: -5px;
  width: 30px;
}
.header-batch {
  color: #dbe1e6;
  width: 108px;
  text-align: start;
  font-size: 0.9em;
}
.batch-textarea {
  padding: 9px 9px;
  border-radius: 5px;
  width: 100%;
  font-size: 1em;
  letter-spacing: 0.3px;
  background-color: #fff !important;
  color: #6c757d !important;
  max-height: 100px;
  min-height: 37px;
  height: 37px;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 7px;
  border: none !important;
}
.footer-batch {
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.inputs-label {
  color: white !important;
  margin-top: 5px !important;
  margin-bottom: 6px !important;
}

.engines-dropdown {
  width: 100% !important;
  max-height: 40px !important;
  border-radius: 8px !important;
  color: black !important;
  background-color: #3e678e !important;
}

.counter {
  display: flex;
  gap: 10px;
  color: white;
  align-items: center;
}

.amount-counter {
  text-decoration: underline;
}

.front-app-container {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  justify-content: center;
  padding-top: 5px;
}

.app-form-inputText {
  height: 45px;
  width: 85%;
  margin-left: auto !important;
  color: #495057;
  background: #ffffff;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

input[type='file'] {
  display: none;
}

.app-form-inputText:disabled {
  background-color: white !important;
}

.app-batch-btn {
  background-color: #3e678e !important;
  min-width: 100px !important;
  padding: 20px !important;
  margin-right: 0 !important;
}

.app-batch-back {
  background-color: transparent !important;
  color: white !important;
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.537) !important;
  min-width: 100px !important;
  padding: 20px !important;
  margin-right: 0 !important;
}

.app-batch-back:hover {
  color: white !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

@media screen and (max-width: 900px) {
  .input-form-textarea {
    min-height: 30vh;
    max-height: 30vh;
    min-width: 70vw;
  }
  .input-form-textarea2 {
    min-width: 70vw;
  }
  .header-batch {
    color: #22384d;
    width: auto;
  }
  .community-img {
    width: 20vw;
  }
  .add-batch-section {
    padding: 45px 30px 20px 30px !important;
    min-width: auto;
  }

  .info-batch-file {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 0px;
  }

  .front-app-container {
    font-size: 0.9em;
    flex-direction: column;
    padding-bottom: -30px;
  }

  .footer-batch {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media screen and (max-width: 578px) {
  .community-img {
    width: 40vw;
  }
}
