.lHeaderSection {
  display: flex;
  justify-content: center;
  padding: 0px 0px 15px 0px;
  border-radius: 20px;
}

.lHeaderImg {
  width: 250px;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 10px;
  padding: 10px 20px 0px 20px;
}

.lHeaderTitle {
  margin: 1rem 0 0 0;
  font-size: 1em;
  font-weight: 330;
  color: rgba(255, 255, 255, 0.769);
}

.lHeaderSubtitle {
  margin: 0rem 0 1rem 50px;
  font-weight: 500;
  font-size: 1em;
  align-self: flex-start;
  color: rgba(255, 255, 255, 0.769);
}

@media screen and (max-width: 900px) {
  .lHeaderImg {
    width: 150px;
  }
  .lHeaderSubtitle {
    margin: 0rem 0 1rem 0;
    align-self: flex-start;
  }
}

@media screen and (max-width: 570px) {
}
