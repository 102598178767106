.my-apps-wrapper {
  height: 100%;
  width: 100%;
}

.my-apps-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-bottom: 0vh;
}

.my-apps-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  height: 70px;
}

.adjust-trash {
  color: #3e678e !important;
  margin-left: -70px !important;
  background-color: transparent !important;
}

.adjust-trash3 {
  color: #3e678e !important;
  margin-left: -50px !important;
  background-color: transparent !important;
}

.adjust-trash3:hover {
  color: red !important;
  background-color: transparent !important;
}

.adjust-trash2 {
  color: #3e678e !important;
  margin-left: -50px !important;
  top: -4px !important;
  background-color: transparent !important;
}

.adjust-trash2:hover {
  color: red !important;
  background-color: transparent !important;
}

.adjust-trash:hover {
  color: red !important;
  background-color: transparent !important;
}

.my-apps-list {
  margin: 0 auto;
  height: calc(100% - 65px);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 100px;
  padding: 1rem 2vw 1rem 2vw;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.my-apps-list::-webkit-scrollbar {
  display: none;
}

.my-apps-list-item {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.new-app-button {
  height: 40px !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
}

.list-empty-apps-wrapper {
  height: calc(79%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.list-empty-apps-message {
  font-size: 1.2em;
  text-align: center;
  color: black;
  font-weight: 400 !important;
  margin: 20px 0 10px 0 !important;
}

.new-app-button {
  font-size: 16px !important;
  height: 32px !important;
  width: 150px !important;
}

.filters-apps {
  margin-top: 1vh;
  height: 40px !important;
}

.lightning {
  color: white !important;
}

.engine-section-wrapper {
}

.title-section {
  color: #185f7394;
  margin: 0;
  font-size: 1.3em;
}
.subtitle-section {
  color: #6f7a88;
  margin-top: 4px;
  margin-right: 30px;
  margin-bottom: 0;
  line-height: 34px;
  font-size: 0.9em;
}

.engine-title-wrapper {
  padding-left: 2.5vw;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 1vw;
  background-color: rgba(0, 0, 0, 0.056) !important;
}

@media screen and (max-width: 900px) {
  .subtitle-section {
    color: #6f7a88;
    margin-top: 1px;
    margin-bottom: 0;
    line-height: 30px;
    font-size: 0.5em;
  }
  .engine-title-wrapper {
    height: 35px;
    width: 100%;
    margin: 0 auto;
  }
  .title-section {
    justify-content: center;
    padding: 0;
    font-size: 0.7em;
  }
  .my-apps-wrapper {
    padding: 0;
  }

  .my-apps-container {
    padding-bottom: 0vh;
  }

  .my-apps-header {
    padding: 1rem;
  }

  .my-apps-header {
    height: 55px;
  }

  .my-apps-list {
    padding: 1rem 2vw 1rem 2vw;
    height: calc(100% - 55px);
  }

  .list-empty-apps-wrapper {
    height: calc(100% - 55px);
  }
}

@media screen and (max-width: 570px) {
  .my-apps-list {
    justify-content: center;
  }
  .adjust-trash {
    color: #3e678e !important;
    margin-left: -90px !important;
    background-color: transparent !important;
  }
  .engine-title-wrapper {
    height: 35px;
    width: 100%;
    margin: 0 auto;
  }
  .my-apps-list-item {
    margin: 3vw;
    display: flex;
    justify-content: center;
  }

  .my-apps-container {
    padding: 0;
    padding-bottom: 70px;
  }
}
