.back-btn {
  margin-left: 40px;
}

@media screen and (max-width: 900px) {
  .back-btn {
    margin-left: 0px;
    min-width: 100%;
  }
}

@media screen and (max-width: 570px) {

}
