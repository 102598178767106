#statsIcon {
  width: 30px;
}

#events-table {
  display: flex;
  margin-left: auto;
}

#events-table td section {
  margin-left: 0px;
}

.table-events-description {
  margin-top: 0px;
  margin-left: 0px;
}

#events-table button {
  width: 25px;
  height: 25px;
  justify-content: center;
  background-color: #f8f9fa;
  border: none;
}

.heading {
  margin-top: 0 !important;
  margin-right: 20px !important;
  font-size: 16px !important;
}

.table-events-wrapper {
  background-color: #f8f9fa77;
  padding: 25px;
  margin: 0 auto;
}

.events-align {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 20px;
}

tr:nth-child(odd) {
  background-color: rgba(70, 69, 69, 0.05);
}

.events-scroller {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

@media screen and (max-width: 570px) {
  .table-events-wrapper {
    background-color: #f8f9fa77;
    padding: 0px;
    margin: 0;
  }
  .event-name {
    font-size: 1em;
    padding: 10px !important;
  }
}

#events-table button img {
  width: 25px;
  height: 25px;
}

.event-name {
  font-size: 1em;
  padding: 20px !important;
}

.events-action {
  background-color: transparent !important;
  width: 25px !important;
  height: 30px !important;
  margin: 5px !important;
  justify-content: center;
  box-shadow: none !important;
}

.events-img {
  width: 25px;
  height: 25px;
}

.action-btns {
  width: 125px !important;
  margin-right: 0 !important;
  padding: 20px !important;
}
