.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding-right: 2vw;
}

.header-btn {
  background-color: transparent !important;
  margin-right: 0 !important;
}

.header-btn:hover {
  background-color: transparent !important;
  opacity: 0.8 !important;
}
