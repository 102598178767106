.engine-header {
  width: 100%;
  height: 0px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.engine-header-img {
  height: 100%;
}

.table-wrap {
  display: flex;
  flex-direction: column;
  margin: 15px 0 !important;
}

.engine-formgroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
}
.engine-switch-container {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: #eeeff0;
  border-radius: 8px;
  padding-left: 10px;
}

.engine-inputform {
  min-height: 35px !important;
  max-height: 200px !important;
  color: rgba(0, 0, 0, 0.826) !important;
}

.engine-form-button-section {
  display: flex;
}

.engine-form-button {
  align-items: flex-end;
  margin: 0 !important;
}

.wrapper-engine-form {
  padding: 70px 20px 20px 20px;
}

@media screen and (max-width: 900px) {
  .engine-inputs-section {
    margin-top: 40px;
  }
  .wrapper-engine-form {
    padding: 2vw;
  }
  .engine-inputform {
    min-width: 100%;
    max-width: 100%;
    min-height: 35px !important;
    max-height: 200px !important;
  }
}
@media screen and (max-width: 570px) {
  .table-wrap {
    display: flex;
    flex-direction: column;
    margin: 20px !important;
  }
}
