.trash-can {
  height: 10vh;
  margin: 60px 0 40px 0;
}

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
