.recovery-external-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.recovery-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 24vh;
}

.update-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 24vh;
}

@media screen and (max-width: 900px) {
  .recovery-container {
    padding-top: 20vh;
  }
  .update-container {
    padding-top: 20vh;
  }
}

@media screen and (max-width: 570px) {
}
