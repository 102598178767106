.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.app-content {
  flex: 1;
}

.header-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding-right: 2vw;
  background: rgb(34, 56, 77);
  background: linear-gradient(125deg, rgba(34, 56, 77, 1) 60%, rgba(62, 103, 142, 1) 130%);
}

.header-active {
  margin-top: -2px;
  width: 100%;
  border-bottom: #f04 solid 2px;
  box-shadow: 1px 1px 2px #f04 !important;
}

.header-actions {
  display: flex;
  margin-left: auto;
  gap: 2vw;
}

.img-logo2 {
  height: 25px;
  margin-left: 2vw;
}

.img-logo {
  min-height: 21px;
  min-width: 25px;
  margin-right: -4px !important;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 0vw;
}

.sebs {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
  height: 56px !important;
  width: 60px !important;
  margin: -2px 0.5vw 0 0.5vw !important;
  padding: 1px !important;
}

.cont-header {
  display: flex;
  justify-content: flex-end;
  min-width: auto;
  gap: 2vw;
}

.sebs:hover {
  opacity: 0.9;
  background-color: transparent !important;
}

.app-container {
  display: flex;
  justify-content: center;
  border: 1px #22384d;
  border-style: solid;
  box-shadow: 2px 2px 7px #0129398a;
  width: 100vw;
  height: 100vh;
  max-height: 1180px !important;
  max-width: 2000px !important;
}

.app-wrapper {
  width: 100%;
  height: 100%;
}

.page-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.head {
  z-index: 1000;
  height: 96px;
  width: 100%;
  background-color: #898c90;
}

.body {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .header-container {
    height: 55px;
    padding-right: 0vw;
  }

  .img-logo2 {
    height: 20px;
    margin-top: 4px;
    margin-left: 3vw;
  }
  .cont-header {
    gap: 0vw;
  }
  .sebs {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: transparent !important;
    height: 56px !important;
    width: 60px !important;
    margin: -2px 0.5vw 0 0.5vw !important;
    padding: 1px !important;
  }
}

@media screen and (max-width: 570px) {
  .header-container {
  }
}
