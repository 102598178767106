.header-flows {
  background-color: #22384d10;
  display: flex;
  justify-content: center;
}

.modeler-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 0rem 0 0rem;
}

.modeler-container {
  height: 100vh;
  overflow: hidden;
}

.modeler-header {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 2rem;
  height: 60px;
  width: 100%;
}

.table-wrapper {
  height: 100%;
  height: 100%;
}

.edit-button {
  background-color: #22384d !important;
  margin-left: 1rem !important;
  border: 1px solid #22384d !important;
}

.edit-button:hover {
  color: #ffffff;
  border-color: #22384d !important;
}

.actionButton {
  height: 20px !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.actionButton:hover {
  background-color: #f04 !important;
}

.create-button {
  padding-top: 4px !important;
  padding-left: 5px !important;
  max-width: 105px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: rgba(246, 245, 245) !important;
  gap: 10px !important;
  margin: 0 !important;
}

.filters-modeler {
  min-width: 265px;
  margin: 0px !important;
}

.pi-plus {
  color: rgba(246, 245, 245) !important;
  margin-right: 0px !important;
}

.table-modeler-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modeler-table {
  max-width: 1280px;
  padding: 1.5rem 1.5rem 0 1.5rem;
  height: 85vh;
  width: 100%;
  margin: 0 auto;
}

.modeler-table-ext {
  max-width: 1280px;
  padding: 1.5rem 1.5rem 0 1.5rem;
  height: 85vh;
  width: 100%;
  margin: 0 auto;
}

.modeler-table2 {
  max-width: 1280px;
}

.modeler-table4 {
  width: calc(100vw - 320px);
  max-width: 1180px;
}

.modeler-table3 {
  width: calc(100vw - 340px);
  max-width: 1180px;
  max-height: 100vh !important;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 auto;
  height: calc(100vh - 45px);
  padding: 2rem 0;
}

.modeler-table::-webkit-scrollbar {
  display: none;
}

.modeler-message {
  font-size: 1.5em;
  color: #22384d;
  text-align: center;
  margin-top: 40px !important;
  margin-bottom: 0 !important;
}

.modeler-filter {
  background-color: rgba(97, 93, 93, 0.8) !important;
}

@media screen and (max-width: 900px) {
  .modeler-table4 {
    width: calc(100vw - 120px);
    max-width: 1180px;
  }
  .modeler-table {
    width: calc(100vw - 66px);
    height: 20vh;
  }

  .modeler-table-ext {
    width: calc(100vw);
    height: 89vh;
  }
  .modeler-table2 {
    max-height: 80vh;
    margin: 0 auto;
  }

  .modeler-table3 {
    max-width: calc(100vw - 70px) !important;
  }

  .table-modeler-wrapper {
    min-height: 100%;
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modeler-wrapper {
    padding: 0;
  }

  .modeler-container {
    padding: 0;
  }

  .table-wrapper {
    margin: 0 1rem;
    padding: 1rem 0 1rem 0;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .table-wrapper::-webkit-scrollbar {
    display: none;
  }

  .button {
    width: 50%;
  }

  .modeler-header {
    width: 100%;
    border-radius: 0px;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .modeler-header::-webkit-scrollbar {
    display: none;
  }

  .sub-header {
    display: flex;
    width: 500px;
  }
}

@media screen and (max-width: 570px) {
  .modeler-table {
    height: 75vh !important;
  }

  .modeler-table3 {
    width: calc(100vw - 90px);
    padding: 10px 1.5rem;
    height: 95vh;
  }
  .modeler-filter {
    margin-left: 0px;
  }

  .create-button {
    padding-top: 7px !important;
    height: 40px !important;
    margin-bottom: 0px !important;
  }

  .modeler-message {
    font-size: 16px;
    margin: 0 !important;
  }

  .table-wrapper {
    margin: 0 1rem;
    padding: 1rem 0 1rem 0;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .table-wrapper::-webkit-scrollbar {
    display: none;
  }
}
