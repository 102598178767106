.activities-wrapper {
  height: calc(100% - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.flex-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-box {
  height: 60px;
  display: flex;
  padding: 1.3rem 1rem 1.3rem 0;
  gap: 10px;
  align-items: center;
  height: 65px;
  width: 60vw;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-box::-webkit-scrollbar {
  display: none;
}

.table-box {
  height: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

.table-box {
  height: calc(100% - 65px);
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.table-box::-webkit-scrollbar {
  display: none;
}

.action-btn {
  margin-bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.419) !important;
}

.search-activities-wrapper {
  display: flex;
  align-items: center;
  background-color: #3e678e;
  padding: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 610px;
  height: 59px;
  margin: 0px 0 10px 0;
  border-bottom: #22384d 1px;
}

.activity-header {
  color: white;
  background-color: #22384d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: auto;
  margin: 0px 10px -10px 0px;
  padding: 5px 20px;
  font-size: 1.7em;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.43);
}

@media screen and (max-width: 900px) {
  .activity-header {
    margin: 0px 10px 0px 0px;
    font-size: 1.2em;
  }
  .search-activities-wrapper {
    width: 530px;
  }
  .activities-wrapper {
    margin-left: 1.2rem;
  }

  .table-box {
    margin-top: 0rem;
    height: calc(100% - 65px);
    overflow: hidden;
    overflow-x: scroll;
  }
  activity-header {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 570px) {
  .search-activities-wrapper {
    margin: 10px 0 10px -10px;
    width: 76vw;
    overflow-x: scroll;
  }
  .filter-box {
    align-items: left;
  }
}
