.event-input-text {
  width: 80%;
}

.event-name-section {
  justify-content: space-between;
  align-items: center;
  padding: 0px !important;
}

.events-section {
  background-color: #9c9c9c31;
  padding: 10px;
  margin-top: 1rem;
}

.form-event-wrapper {
  padding: 60px 20px 20px 20px;
}

#programmedEvents section {
  justify-content: space-around;
  align-items: center;
}

.events-dropdown {
  width: 200px;
  margin-top: 0.5rem;
}

#weebhookEvents input {
  margin-left: auto;
  width: 80%;
}

.programmed-form-control {
  margin-top: 1rem;
  align-items: center;
}

.programmed-input {
  margin-left: auto !important;
  width: 80%;
}

.programmed-input-cron {
  margin-left: auto !important;
  margin-right: 1rem !important;
  width: 54.6%;
}

.webhook-events-formControl {
  display: flex;
  margin-top: 10px;
  justify-content: left;
  align-items: left;
}

.title-event {
  font-size: 1.5em;
  font-weight: 400;
  margin-left: 0vw;
}

.float-end {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.labels-event {
  display: flex;
  width: 100px;
  text-align: left;
  align-items: center;
  color: white;
}

.events-radioButton {
  margin-right: 0.5rem;
}

.event-btn {
  margin: 0 0 0 10px !important;
  padding: 20px !important;
  color: white !important;
}

.event-btn:disabled {
  background-color: gray !important;
  opacity: 0.8;
}

.event-input-label {
  margin-left: 15px;
  color: white;
}

.event-input-title {
  font-size: 20px;
  color: wheat;
  background-color: #3e678e;
  padding: 5px 10px;
  border-radius: 8px;
}

.event-btn {
  width: 160px;
}

@media screen and (max-width: 570px) {
  .title-event {
    font-size: 1.2em;
    font-weight: 400;
    margin-left: 0vw;
  }

  .event-btn {
    min-width: 190px !important;
  }
}
